import React from 'react';
import { format } from 'd3-format'

import Box from '../../../components/Box'
import Text from '../../../components/Text'
import Image from '../../../components/Image'
import Container from '../../../components/Container'
import DotsBg from '../../../components/DotsBg'
import RoundTitleLabel from '../../../components/RoundTitleLabel'
import StackedBars from '../../../components/Charts/StackedBars'
import { schemas } from '../../../components/ThemeProvider/theme'

import FullRight from '../../FullRight'
import FullLeft from '../../FullLeft'
import getYtickLabel from '../../getYtickLabel'

import useCSV from '../../../utils/useCSV'

import left from './left.svg'
import right from './right.svg'
import frame from './frame.svg'

import csv from './p10.csv'

const Page = ({ resizeRatio, activated, active, em, containerWidth, isMobile, toActive }) => {
  const { data } = useCSV(csv, toActive)
  return (
    <Box height="100%" position="relative" bg="orange">
      <FullRight src={right} containerWidth={containerWidth} />
      <Box
        position="absolute"
        borderBottom="0.125em solid"
        style={{
          left: 134 * resizeRatio,
          right: 134 * resizeRatio,
          top: 733 * resizeRatio,
        }}
      />
      <Container position="relative" height="100%" width={containerWidth}>
        <Box pt="3.5em" textAlign="center">
          <Text.h2 color="primary">前往哪裡買票最方便呢？</Text.h2>
          <Text.h4 color="white">不論年齡都是以「網路購票」為主</Text.h4>
        </Box>
        <DotsBg
          position="absolute"
          style={{
            width: 1006 * resizeRatio,
            top: 172 * resizeRatio,
            left: 187 * resizeRatio,
          }}
        >
          <Image data-src={frame} />
          <Box position="absolute" top="0" left="2.75em" right="2.75em" bottom="0">
            <RoundTitleLabel mt="3.75em">2013-2019年 各年齡層購票張數佔比</RoundTitleLabel>
            <Box position="absolute" top="10em" left="0" right="0" bottom="3.375em">
              {data && (
                <StackedBars
                  activated={activated}
                  width={918 * resizeRatio}
                  height={332 * resizeRatio}
                  data={data}
                  xAccessor={d => d['年齡']}
                  keys={['分銷點購買', '票口購買', '網路購買']}
                  xTick={{ label: '（歲）' }}
                  yTick={{
                    label: getYtickLabel('購票張數佔比', '％'),
                    tickFormat: d => String(d * 100),
                    tickValues: [0, 0.25, 0.50, 0.75, 1],
                  }}
                  valueFormat={format('.0%')}
                  tooltipFormat={format('.0%')}
                  margin={{
                    top: 2,
                    left: 5,
                    right: 8.5,
                    bottom: 2.25,
                  }}
                  colors={schemas.purples}
                  em={em}
                  isMobile={isMobile}
                />
              )}
            </Box>
          </Box>
        </DotsBg>
      </Container>
      <FullLeft src={left} containerWidth={containerWidth} />
    </Box>
  );
};

export default Page;
