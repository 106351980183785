import React from 'react';
import styled from 'styled-components';
import { space, layout, opacity } from 'styled-system';
import tag from 'clean-tag';

import blacklist from './utils/blacklist';

const Image = styled(tag.img)`
  ${space}
  ${layout}
  ${opacity}
  user-select: none;
`;

Image.defaultProps = {
  blacklist,
  width: 1,
};

Image.displayName = 'Image';

Image.height = p => <Image height="100%" width="auto" {...p} />

export default Image;
