import styled from 'styled-components'

import Box from '../Box'
import dots from './dots.svg'

export default styled(Box)`
  background-image: url('${dots}');
  background-repeat: repeat;
  background-color: #16221d;
  background-size: 30vw 30vw;
`
