import React from 'react';
import styled, { keyframes } from 'styled-components'

import Text from './Text'

const flicker = keyframes`
10%, 90% { opacity: 1 }
50% { opacity: 0.2 }
`

const Comp = styled(Text.h2)`
  animation: ${flicker} 1.5s infinite;
`
const Loading = () => {
  return (
    <Comp color="primary">
      Loading...
    </Comp>
  );
};

export default Loading;
