import React from 'react';
import styled from 'styled-components';
import range from 'lodash/range';
import {
  typography,
  space,
  color,
  display,
  position,
} from 'styled-system';
import tag from 'clean-tag';

import blacklist from './utils/blacklist';
import injectProps from './utils/injectProps';

const BaseText = styled(tag)`
  margin-top: 0;
  margin-bottom: 0;
  ${typography}
  ${display}
  ${space}
  ${color}
  ${position}
  ${injectProps('textTransform')}
  ${injectProps('verticalAlign')}
  ${injectProps('whiteSpace')}
`;

const Text = ({ children, noResize, ...props }) => (
  <BaseText {...props}>
    {noResize ? children : <BaseText is="span" fontSize={['110%', '100%']}>{children}</BaseText>}
  </BaseText>
)

Text.defaultProps = {
  is: 'p',
  fontSize: '1em',
  // lineHeight: 1.5,
  blacklist,
};

Text.inline = (props) => <Text is="span" {...props} />;
Text.bold = (props) => <Text fontWeight="bold" {...props} />;
Text.thin = (props) => <Text fontWeight="200" {...props} />;

const fontSizes = [
  '3.5em',
  '2.75em',
  '2.5em',
  '1.625em',
  '1.25em',
  '1em',
]

range(1, 7).forEach((key) => {
  const h = `h${key}`;
  Text[h] = (props) => (
    <Text
      is={h}
      fontSize={fontSizes[key - 1]}
      fontFamily={key <= 3 && '"Gen Jyuu Gothic"'}
      {...props}
    />
  );
});

Text.displayName = 'Text';

export default Text;
