import React from 'react'
import SVG from './SVG'

const FB = () => {
  return (
    <SVG viewBox="0 0 40 40" width="2em">
      <path id="Path_1115" data-name="Path 1115" d="M25,21H21.7v11.6H16.9V21H14.6v-4.1h2.3v-2.6c0-1.9.9-4.9,4.9-4.9h3.6v4H22.8a1,1,0,0,0-1,.9v2.6h3.7Z" fill="#fff"/>
    </SVG>
  )
}

export default FB
