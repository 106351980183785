import React, { createElement, useState, useEffect, useCallback } from 'react'
import ReactFullpage from '@fullpage/react-fullpage';

import useResponsive from '../contexts/mediaQuery/useResponsive'

import StepPreloader from '../containers/StepPreloader'
import preload from '../preload'

import DotsBg from '../components/DotsBg'
import Box from '../components/Box'
import Loading from '../components/Loading'
import browser from '../components/utils/browser'

import Page1 from './pages/1'
import Page2 from './pages/2'
import Page3 from './pages/3'
import Page4 from './pages/4'
import Page5 from './pages/5'
import Page6 from './pages/6'
import Page7 from './pages/7'
import Page8 from './pages/8'
import Page9 from './pages/9'
import Page10 from './pages/10'
import Page11 from './pages/11'
import Page12 from './pages/12'
import Page13 from './pages/13'
import Page14 from './pages/14'
import Page15 from './pages/15'
import Page16 from './pages/16'
import Page17 from './pages/17'
import Page18 from './pages/18'

import Transition1 from './Transitions/1'
import Transition2 from './Transitions/2'
import Transition7 from './Transitions/7'
import Transition9 from './Transitions/9'
import Transition10 from './Transitions/10'
import Transition11 from './Transitions/11'
import Transition12 from './Transitions/12'
import Transition13 from './Transitions/13'
import Transition15 from './Transitions/15'
import Transition17 from './Transitions/17'

import Menu from './Menu'

const isDesktop = browser.getPlatformType() === 'desktop'

const extractPage = () => Math.max(window.location.hash.replace(/\D+/, '') - 1, 0)


const withHistoryWatch = (i) => SubComp => props => {
  const [active, setActive] = useState(() => extractPage() === i)
  const [toActive, setToActive] = useState()
  const [activated, setActivated] = useState(active)
  useEffect(() => {
    if (!isDesktop) {
      const secInterval = setInterval(() => {
        const page = extractPage()
        setActive(page === i)
        setToActive(page + 1 === i)
        if (page === i) {
          setActivated(true)
          if (i) clearInterval(secInterval)
        }
      }, 500)

    }
    const handleChange = () => {
      const page = extractPage()
      setTimeout(() => {
        setTimeout(() => {
          const isActive = page === i
          setActive(isActive)
          setToActive(page + 1 === i)
          if (isActive) {
            setActivated(true)
          }
        })
      }, 500)
    }
    window.addEventListener('hashchange', handleChange)
    return function cleanup() {
      window.removeEventListener('hashchange', handleChange)
    }
  }, [])
  return <SubComp activated={activated} active={active} toActive={toActive || active} {...props} />
}

const shouldUpdate = SubComp => ({ active, toActive, ...props }) => {
  const rendition = <SubComp active={active} toActive={toActive} {...props} />
  return (active || toActive) ? rendition : useCallback(rendition, [])
}

const { pages, transitions } = [
  { page: Page1, transition: Transition1 },
  { page: Page2, transition: Transition2 },
  Page3,
  Page4,
  Page5,
  Page6,
  { page: Page7, transition: Transition7 },
  Page8,
  { page: Page9, transition: Transition9 },
  { page: Page10, transition: Transition10 },
  { page: Page11, transition: Transition11 },
  { page: Page12, transition: Transition12 },
  { page: Page13, transition: Transition13 },
  Page14,
  { page: Page15, transition: Transition15 },
  Page16,
  { page: Page17, transition: Transition17 },
  Page18,
].reduce((ret, p, i) => {
  if (typeof p === 'function') {
    ret.pages[i] = withHistoryWatch(i)(shouldUpdate(p))
  } else {
    ret.pages[i] = withHistoryWatch(i)(shouldUpdate(p.page))
    ret.transitions[i] = p.transition
  }
  return ret
}, {
  pages: [],
  transitions: [],
})

const TheMenu = withHistoryWatch(0)(({ activated, active, ...props }) => (
  <Menu hide={active} {...props} />
))

const HomePage = ({ hasInited }) => {
  const {
    containerWidth,
    resizeRatio,
    inited: responsiveInited,
    isMobile,
    em,
  } = useResponsive()
  const [inited, setLoaded] = useState()
  return (
    <>
      {responsiveInited && (
        <ReactFullpage
          //fullpage options
          licenseKey="6313A8FC-72634A8F-B83D01C8-ACBFD3D5"
          scrollingSpeed={1000}
          // scrollOverflow
          autoScrolling={isDesktop}
          // autoScrolling={false}
          render={({ fullpageApi }) => {
            return (
              <ReactFullpage.Wrapper>
                {pages.reduce((all, p, i) => all.concat(
                  <section
                    className="section"
                    data-anchor={`page${i + 1}`}
                    key={i}
                  >
                    {createElement(p, {
                      fullpageApi,
                      resizeRatio,
                      inited: i ? true : inited,
                      hasInited,
                      containerWidth,
                      isMobile,
                      em,
                    })}
                  </section>
                , transitions[i] ? (
                  <div key={`t${i}`} style={{ pointerEvents: 'none' }}>
                    {createElement(transitions[i], { resizeRatio, containerWidth, isMobile, em })}
                  </div>
                ) : null), []
                )}
              </ReactFullpage.Wrapper>
            );
          }}
        />
      )}
      {inited ? (
        <TheMenu />
      ) : (
        <DotsBg
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          pointerEvents="none"
          zOrder={4}
        >
          <Box position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" color="primary">
            <Loading />
          </Box>
        </DotsBg>
      )}
      <StepPreloader list={preload} onFirstLoaded={() => setLoaded(true)} />
    </>
  )
}

export default HomePage
