import React, { useState, useEffect } from 'react';
import Measure from 'react-measure'
import { TiArrowSortedDown } from 'react-icons/ti'

import Link from '../../../components/Link'
import Circle from '../../../components/Circle'
import Box from '../../../components/Box'
import Text from '../../../components/Text'
import NumberAndText from '../../../components/NumberAndText'
import Image from '../../../components/Image'
import SVG from '../../../components/SVG'
import DotsBg from '../../../components/DotsBg'
// import browser from '../../../components/utils/browser'

import FullLeft from '../../FullLeft'
import FullRight from '../../FullRight'

import left from './left.svg'
import right from './right.svg'
import logo from './logo.svg'

const duration = 1500

// const isiOS = browser.getOSName() === 'iOS'
// const isMobile = browser.getPlatformType !== 'desktop'

const Page = ({ fullpageApi, resizeRatio, inited, hasInited = false, containerWidth }) => {
  const [pos, setPos] = useState({})
  const [animated, setAnimated] = useState(hasInited)
  const [doInited, setDoInted] = useState(hasInited)
  useEffect(() => {
    if (inited && !hasInited) {
      setTimeout(() => {
        setDoInted(true)
        setTimeout(() => {
          setAnimated(true)
          setTimeout(() => {
            window.dispatchEvent(new Event('re:FisrtPageInited'));
          }, 1000)
        }, duration * 1.2)
      })
    }
  }, [inited])

  return (
    <DotsBg height="100%" position="relative">
      <Measure onResize={({ bounds }) => setPos(bounds)} bounds>
        {({ measureRef }) => (
          <Box position="absolute" width="100%" height="100%" ref={measureRef}>
            <SVG width="100%" height="100%">
              <polygon
                fill="#fcc013"
                opacity={animated ? 0.8 : 0}
                points={[
                  [271 * resizeRatio, 290 * resizeRatio],
                  [289 * resizeRatio, 318 * resizeRatio],
                  [pos.width / 2 - 100 * resizeRatio, 348 * resizeRatio],
                  [pos.width / 2 - 164 * resizeRatio, 106 * resizeRatio],
                ].map(d => d.join()).join(' ')}
              />
              <polygon
                fill="#fcc013"
                opacity={animated ? 0.8 : 0}
                points={[
                  [pos.width - (1366 - 1095) * resizeRatio, 290 * resizeRatio],
                  [pos.width - (1366 - 1079) * resizeRatio, 318 * resizeRatio],
                  [pos.width / 2 + 100 * resizeRatio, 348 * resizeRatio],
                  [pos.width / 2 + 164 * resizeRatio, 106 * resizeRatio],
                ].map(d => d.join()).join(' ')}
              />
            </SVG>
          </Box>
        )}
      </Measure>
      <FullLeft transition={`transform ${duration}ms`} transform={`translateX(${doInited ? 0 : -100}%)`} src={left} containerWidth={containerWidth} />
      <FullRight transition={`transform ${duration}ms`} transform={`translateX(${doInited ? 0 : 100}%)`} src={right} containerWidth={containerWidth} />

      <Box position="absolute" top="5.375em" left="50%" transform="translateX(-50%)" opacity={+animated}>
        <Circle width="16.375em" bg="primary" />
        <Circle width="16.375em" bg="primary" ml="-4.5em" />
      </Box>
      <Box pt="10em" textAlign="center" position="relative">
        <Box opacity={+animated}>
          <Text.h1>數據再登場</Text.h1>
          <Text.h3>兩廳院售票消費行為年度報告</Text.h3>
        </Box>
        <Box color="white" mt="7em" opacity={+animated} transition={`opacity ${duration}ms ease-in-out`}>
          <Text.h5 mb="1em">
          作為國內代表性的藝文票券銷售平台「兩廳院售票系統」，<br />
          期許成為藝文觀眾與場館、團隊的橋梁，<br />
          <NumberAndText lineHeight="1" is="span">今年再度呈現2013-2019年表演藝術大數據，</NumberAndText><br />
          以拓展數據共享的價值
          </Text.h5>
          <Text>本次委託「國立交通大學統計學研究所」分析，<br />
          展現國內藝文消費的樣貌及產業發展趨勢，回饋表演藝術產業多元的行銷應用參考。</Text>
        </Box>
      </Box>
      <Box
        pt="3em"
        opacity={+animated}
        transition={`opacity ${duration}ms ease-in-out ${duration / 2}ms`}
        textAlign="center"
      >
        <Circle
          width="2.75em"
          bg="primary"
          onClick={() => {
            fullpageApi.moveSectionDown()
          }}
        >
          <TiArrowSortedDown size="1.55em" />
        </Circle>
      </Box>
      <Box left="1.5em" top="1.5em" width="8.5em" position="absolute">
        <Link href="https://www.artsticket.com.tw/CKSCC2005/home/home00/index.aspx">
          <Image data-src={logo} />
        </Link>
      </Box>
    </DotsBg>
  );
};

export default Page;
