import React from 'react'

import Box from './Box'
import NumberAndText from './NumberAndText'

const RoundTitleLabel = ({
  children,
  ...props
}) => {
  return (
    <Box
      bg="#333333"
      borderRadius="3.5em"
      px="2.75em"
      pt="0.75em"
      pb="0.625em"
      display="inline-block"
      {...props}
    >
      <NumberAndText is="h5" fontSize="1.5em" color="white">{children}</NumberAndText>
    </Box>
  )
}

export default RoundTitleLabel
