import React from 'react';

import Box from '../../../components/Box'
import Flex from '../../../components/Flex'
import Text from '../../../components/Text'
import Image from '../../../components/Image'
import Circle from '../../../components/Circle'
import Container from '../../../components/Container'
import DotsBg from '../../../components/DotsBg'

import pintung from './pintung.svg'
import taichung from './taichung.svg'
import weiwuying from './weiwuying.svg'

const data = [
  {
    theater: '臺中國家歌劇院',
    descrip: [
      {
        title: '來自台中地區的觀眾',
        rate: '1.3'
      },
      {
        title: '來自其他地區的觀眾',
        rate: '1.2',
        note: '(人數較多來自北市、新北)'
      },
      {
        img: taichung,
      }
    ],
    openDay: '2016.10 開幕'
  },
  {
    theater: '衛武營國家藝術文化中心',
    descrip: [
      {
        title: '來自高雄地區的觀眾',
        rate: '1.6'
      },
      {
        title: '來自其他地區的觀眾',
        rate: '1.3',
        note: '(人數較多來自北市、台南)'
      },
      {
        img: weiwuying,
      }
    ],
    openDay: '2018.10 開幕'
  },
  {
    theater: '屏東演藝廳',
    descrip: [
      {
        title: '來自屏東地區的觀眾',
        rate: '1.6'
      },
      {
        title: '來自其他地區的觀眾',
        rate: '1.2',
        note: '(人數較多為高雄、花蓮，南投則減少)'
      },
      {
        img: pintung,
      }
    ],
    openDay: '2016.3 開幕'
  },
]

const Page = ({ containerWidth }) => {
  return (
    <DotsBg height="100%">
      <Container pt="2.5em" position="relative" height="100%" width={containerWidth}>
        <Box textAlign="center">
          <Text.h2 color="yellow" fontSize="2.5em">開啓新的人口樣態</Text.h2>
          <Text.h4 color="white" fontSize="1.375em">場館開幕後為每場表演增值外，</Text.h4>
          <Text.h4 color="white" fontSize="1.375em">各地區的觀眾有了更多選擇，也為當地帶來新的產業樣態。</Text.h4>
        </Box>
        <Flex mt="2.625em" justifyContent="center">
          {data.map(({ theater, descrip, openDay}, i) => (
            <Box textAlign="center" width={1} px="1em" key={i}>
              <Box.inline
                bg="lightBlack"
                px="2.125em"
                pt="0.75em"
                pb="0.625em"
                fontSize="1.5em"
                borderRadius="1.5em"
                color="white"
              >{theater}</Box.inline>
              <Box position="relative">
                <Box position="absolute" left="3.125em" top="1em" bottom="1em" width={[0, '1px']} bg="white" />
                {descrip.map(({ title, rate, img, note }, k) => img ? (
                  <Box position="relative" mt="3em" key={k}>
                    <Image data-src={img} />
                    <Box position="absolute" bottom="0.2em" color="white" left="20%">{openDay}</Box>
                  </Box>
                ) : (
                  <Flex mt="2em" justifyContent="center" position="relative" alignItems="center" key={k}>
                    <Box mx="0.875em" position="relative" fontSize="1.25em" textAlign="left">
                      <Text color="yellow">{title}</Text>
                      <Text color="white">開幕後，購票會員增為</Text>
                      <Box position="absolute" top="110%" left="0" width="150%">
                        {note && <Text color="white" fontSize="0.75em">{note}</Text>}
                      </Box>
                      <Box position="absolute" left="-1.25em" top="0">
                        <Circle bg="yellow" width="0.5em" />
                      </Box>
                    </Box>
                    <Box color="yellow"><Text.inline fontSize="2.5em" px="0.25em">{rate}</Text.inline>倍</Box>
                  </Flex>
                ))}
              </Box>
            </Box>
          ))}
        </Flex>
        <Text ml="1em" mt="2em" color="white">單位：每場次購票會員人數</Text>
      </Container>
    </DotsBg>
  );
};

export default Page;
