import React, { useState, useRef, useEffect } from 'react';

import Box from '../../../components/Box'
import Flex from '../../../components/Flex'
import Text from '../../../components/Text'
import Image from '../../../components/Image'
import Button from '../../../components/Button'
import DotsBg from '../../../components/DotsBg'
import Container from '../../../components/Container'
import NumberAndText from '../../../components/NumberAndText'
import Carousel from '../../../components/Carousel'
import RoundTitleLabel from '../../../components/RoundTitleLabel'
import Circle from '../../../components/Circle';

import bg from './bg.svg'

import data from './data'

const renderList = (init, loadImg) => (d, i) => (
  <Flex key={i} my="0.75em" alignItems="center">
    <Box position="relative" width="4.5em">
      <Box borderRadius="50%" overflow="hidden">
        {loadImg && (
          <picture>
            <source type="image/webp" srcSet={d.webp} />
            <Image src={d.img} />
          </picture>
        )}
      </Box>
      <Box width="2em" position="absolute" left="-0.75em" bottom="-0.75em">
        <Circle width={1} bg="primary" fontSize="1.25em">{init + i}</Circle>
      </Box>
    </Box>
    <Box pl="0.9em" flex="1" whiteSpace="pre-wrap" {...(d.props || {})}>
      <Box transform={(d.name[0] === '《' || d.name[0] === '【') && 'translateX(-0.5em)'}>
        <Text.h5 noResize color="primary" fontSize="1.125em">{d.name}</Text.h5>
      </Box>
      <Text noResize color="white" fontSize="0.9em" opacity={0.8}>{d.by}</Text>
    </Box>
  </Flex>
)

const Page = ({ resizeRatio, containerWidth, toActive }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [loadImg, setLoadImg] = useState()
  useEffect(() => {
    if (toActive) setLoadImg(true)
  }, [toActive])

  const prevIndex = activeIndex === 0 ? data.length - 1 : activeIndex - 1
  const nextIndex = activeIndex === data.length - 1 ? 0 : activeIndex + 1
  const slickRef = useRef()
  return (
    <Box height="100%" position="relative" bg="orange">
      <Container position="relative" width={containerWidth}>
        <Box position="absolute" top="0" left="0" right="0" pointerEvents="none">
          <Image data-src={bg} />
        </Box>
        <Box pt="1em">
          <Text.h2 color="primary" textAlign="center">2019年 熱門表演回顧</Text.h2>
        </Box>
        <Flex position="absolute" style={{ top: 102 * resizeRatio }} left="0" right="0" justifyContent="center">
          {data.map((d, i) => (
            <Button
              key={i}
              mx="0.25em"
              width="7em"
              active={activeIndex === i}
              onClick={() => slickRef.current.slickGoTo(i)}
            >{d.name}</Button>
          ))}
        </Flex>
        <DotsBg
          position="absolute"
          style={{
            top: 160 * resizeRatio,
            left: 186 * resizeRatio,
            right: 186 * resizeRatio,
            height: 563 * resizeRatio,
          }}
        >
          <Carousel
            config={{ afterChange: setActiveIndex }}
            prevLabel={data[prevIndex].name}
            nextLabel={data[nextIndex].name}
            arrowsOffset="5.5em"
            ref={slickRef}
          >
            {data.map(d => (
              <Box key={d.name} py="1.5em">
                <Flex alignItems="center">
                  <RoundTitleLabel mx={['2em', '2.25em']} fontSize={['0.9em', '1em']}>{d.name} TOP 10</RoundTitleLabel>
                  {d.note && <NumberAndText color="white">({d.note})</NumberAndText>}
                </Flex>
                <Flex mt="1em">
                  <Box px="2em" flex="1" borderRight="1px solid rgba(255,255,255,0.5)">
                    {d.list.slice(0, 5).map(renderList(1, loadImg))}
                  </Box>
                  <Box px="2em" flex="1">
                    {d.list.slice(5).map(renderList(6, loadImg))}
                  </Box>
                </Flex>
              </Box>
            ))}
          </Carousel>
        </DotsBg>
        <Box position="absolute" style={{ top: (160 + 563 + 16) * resizeRatio, left: 186 * resizeRatio, }}>
          <NumberAndText color="white">
            註：節目排名依據2019年節目實售票數排名，包含同一節目巡演場次票數。 中小型演出依據可售票數規模分類。
          </NumberAndText>
        </Box>
      </Container>
    </Box>
  );
};

export default Page;
