import React from 'react';

import Box from '../Box'
import Image from '../Image'

import dance from './dance.svg'
import family from './family.svg'
import music from './music.svg'
import play from './play.svg'

const Icons = ({ src, ...props }) => {
  return (
    <Box.inline width="1em" {...props}>
      <Image src={src} />
    </Box.inline>
  );
};

Icons.dance = p => <Icons src={dance} {...p} />
Icons.family = p => <Icons src={family} {...p} />
Icons.music = p => <Icons src={music} {...p} />
Icons.play = p => <Icons src={play} {...p} />

export default Icons;
