
export default [
  [
    require('./containers/pages/1/left.svg'),
      require('./containers/pages/1/logo.svg'),
      require('./containers/pages/1/right.svg'),
      require('./containers/Transitions/1/triangle-l.svg'),
      require('./containers/Transitions/1/triangle-r.svg')
  ],
  [
    require('./containers/pages/2/left.svg'),
      require('./containers/pages/2/right.svg'),
      require('./containers/pages/2/stage.svg'),
      require('./containers/pages/2/ticket.svg'),
      require('./containers/pages/2/window.svg'),
      require('./containers/Transitions/2/hole.svg'),
      require('./containers/Transitions/2/left.svg'),
      require('./containers/Transitions/2/right.svg')
  ],
  [
    require('./containers/pages/4/frame.svg'),
      require('./containers/pages/4/light.svg')
  ],
  [
    require('./containers/pages/5/bg.svg'),
      require('./containers/pages/5/coin.svg'),
      require('./containers/pages/5/hand.svg')
  ],
  [
    require('./containers/pages/6/bg.svg')
  ],
  [
    require('./containers/pages/7/key.svg'),
      require('./containers/pages/7/right-bottom.svg'),
      require('./containers/pages/7/right.svg'),
      require('./containers/Transitions/7/right.svg'),
      require('./containers/Transitions/7/stairs.svg')
  ],
  [
    require('./containers/pages/8/bg.svg'),
      require('./containers/pages/8/left.svg')
  ],
  [
    require('./containers/pages/9/bg.svg'),
      require('./containers/pages/9/coin.svg'),
      require('./containers/pages/9/frame.svg'),
      require('./containers/pages/9/ticket.svg'),
      require('./containers/Transitions/9/left.svg'),
      require('./containers/Transitions/9/right.svg')
  ],
  [
    require('./containers/pages/10/frame.svg'),
      require('./containers/pages/10/left.svg'),
      require('./containers/pages/10/right.svg'),
      require('./containers/Transitions/10/watch.svg')
  ],
  [
    require('./containers/pages/11/bg.svg'),
      require('./containers/pages/11/numbers.svg'),
      require('./containers/Transitions/11/bottom.svg')
  ],
  [
    require('./containers/pages/12/frame.svg'),
      require('./containers/pages/12/left.svg'),
      require('./containers/Transitions/12/left.svg'),
      require('./containers/Transitions/12/right.svg')
  ],
  [
    require('./containers/pages/13/bg.svg'),
      require('./containers/pages/13/img/1-1.webp'),
      require('./containers/pages/13/img/1-10.webp'),
      require('./containers/pages/13/img/1-2.webp'),
      require('./containers/pages/13/img/1-3.webp'),
      require('./containers/pages/13/img/1-4.webp'),
      require('./containers/pages/13/img/1-5.webp'),
      require('./containers/pages/13/img/1-6.webp'),
      require('./containers/pages/13/img/1-7.webp'),
      require('./containers/pages/13/img/1-8.webp'),
      require('./containers/pages/13/img/1-9.webp'),
      require('./containers/pages/13/img/2-1.webp'),
      require('./containers/pages/13/img/2-10.webp'),
      require('./containers/pages/13/img/2-2.webp'),
      require('./containers/pages/13/img/2-3.webp'),
      require('./containers/pages/13/img/2-4.webp'),
      require('./containers/pages/13/img/2-5.webp'),
      require('./containers/pages/13/img/2-6.webp'),
      require('./containers/pages/13/img/2-7.webp'),
      require('./containers/pages/13/img/2-8.webp'),
      require('./containers/pages/13/img/2-9.webp'),
      require('./containers/pages/13/img/3-1.webp'),
      require('./containers/pages/13/img/3-10.webp'),
      require('./containers/pages/13/img/3-2.webp'),
      require('./containers/pages/13/img/3-3.webp'),
      require('./containers/pages/13/img/3-4.webp'),
      require('./containers/pages/13/img/3-5.webp'),
      require('./containers/pages/13/img/3-6.webp'),
      require('./containers/pages/13/img/3-7.webp'),
      require('./containers/pages/13/img/3-8.webp'),
      require('./containers/pages/13/img/3-9.webp'),
      require('./containers/pages/13/img/4-1.webp'),
      require('./containers/pages/13/img/4-10.webp'),
      require('./containers/pages/13/img/4-2.webp'),
      require('./containers/pages/13/img/4-3.webp'),
      require('./containers/pages/13/img/4-4.webp'),
      require('./containers/pages/13/img/4-5.webp'),
      require('./containers/pages/13/img/4-6.webp'),
      require('./containers/pages/13/img/4-7.webp'),
      require('./containers/pages/13/img/4-8.webp'),
      require('./containers/pages/13/img/4-9.webp'),
      require('./containers/pages/13/img/5-1.webp'),
      require('./containers/pages/13/img/5-10.webp'),
      require('./containers/pages/13/img/5-2.webp'),
      require('./containers/pages/13/img/5-3.webp'),
      require('./containers/pages/13/img/5-4.webp'),
      require('./containers/pages/13/img/5-5.webp'),
      require('./containers/pages/13/img/5-6.webp'),
      require('./containers/pages/13/img/5-7.webp'),
      require('./containers/pages/13/img/5-8.webp'),
      require('./containers/pages/13/img/5-9.webp'),
      require('./containers/pages/13/img/6-1.webp'),
      require('./containers/pages/13/img/6-10.webp'),
      require('./containers/pages/13/img/6-2.webp'),
      require('./containers/pages/13/img/6-3.webp'),
      require('./containers/pages/13/img/6-4.webp'),
      require('./containers/pages/13/img/6-5.webp'),
      require('./containers/pages/13/img/6-6.webp'),
      require('./containers/pages/13/img/6-7.webp'),
      require('./containers/pages/13/img/6-8.webp'),
      require('./containers/pages/13/img/6-9.webp')
  ],
  [
    require('./containers/pages/14/bg.svg')
  ],
  [
    require('./containers/pages/15/c.svg'),
      require('./containers/pages/15/dots.svg'),
      require('./containers/pages/15/down.svg'),
      require('./containers/pages/15/n.svg'),
      require('./containers/pages/15/nn.svg'),
      require('./containers/pages/15/no.svg'),
      require('./containers/pages/15/pattern.svg'),
      require('./containers/pages/15/s.svg'),
      require('./containers/pages/15/up.svg'),
      require('./containers/Transitions/15/ticket.svg')
  ],
  [
    require('./containers/pages/16/bg.svg')
  ],
  [
    require('./containers/pages/17/pintung.svg'),
      require('./containers/pages/17/taichung.svg'),
      require('./containers/pages/17/weiwuying.svg'),
      require('./containers/Transitions/17/stairs.svg')
  ],
  [
    require('./containers/pages/18/bglogo.svg')
  ]
];
