import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react';
import ReactDOM from 'react-dom';
import WebFont from 'webfontloader'

import ThemeProvider from './components/ThemeProvider'
import MediaProvider from './contexts/mediaQuery/MediaProvider'

import App from './App';
// import * as serviceWorker from './serviceWorker';

WebFont.load({
  google: {
    families: ['Noto Sans TC:400,700']
  },
  custom: {
    families: ['Baloo Bhai 2', 'Gen Jyuu Gothic'],
    testStrings: {
      'Baloo Bhai 2': '0%',
      'Gen Jyuu Gothic': '嗨L',
    },
  },
});

window.location.hash = ''

ReactDOM.render(
  <ThemeProvider>
    <MediaProvider>
      <App />
    </MediaProvider>
  </ThemeProvider>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
