export default [
  {
    name: '現代戲劇',
    list: [
      {
        img: require('./img/1-1.png'),
        webp: require('./img/1-1.webp'),
        name: '《再會吧 北投》PLUS',
        by: '綠光劇團',
      },
      {
        img: require('./img/1-2.png'),
        webp: require('./img/1-2.webp'),
        name: '《人間條件二-她與她生命中的男人們》',
        by: '綠光劇團',
      },
      {
        img: require('./img/1-3.png'),
        webp: require('./img/1-3.webp'),
        name: '音樂劇《一個美國人在巴黎》',
        by: '國家表演藝術中心臺中國家歌劇院',
      },
      {
        img: require('./img/1-4.png'),
        webp: require('./img/1-4.webp'),
        name: '《明晚，空中見》',
        by: '故事工廠',
      },
      {
        img: require('./img/1-5.png'),
        webp: require('./img/1-5.webp'),
        name: '搖滾音樂劇《吻我吧娜娜》\n張雨生獨一無二代表作',
        by: '果陀劇場',
      },
      {
        img: require('./img/1-6.png'),
        webp: require('./img/1-6.webp'),
        name: '《最後14堂星期二的課》\n  2019年限定加演場',
        by: '果陀劇場',
      },
      {
        img: require('./img/1-7.png'),
        webp: require('./img/1-7.webp'),
        name: '《花吃花》',
        by: '表演工作坊文化創意股份有限公司',
      },
      {
        img: require('./img/1-8.png'),
        webp: require('./img/1-8.webp'),
        name: '《莊子兵法》',
        by: '故事工廠',
      },
      {
        img: require('./img/1-9.png'),
        webp: require('./img/1-9.webp'),
        name: '《西哈遊記－魔二代再起》',
        by: '為所欲為音樂文化事業有限公司',
      },
      {
        img: require('./img/1-10.png'),
        webp: require('./img/1-10.webp'),
        name: '《瘋狂偶像劇》浪漫加演',
        by: '全民大劇團',
      }
    ]
  },
  {
    name: '傳統戲曲',
    list: [
      {
        img: require('./img/2-1.png'),
        webp: require('./img/2-1.webp'),
        name: '唐美雲歌仔戲團《月夜情愁》',
        by: '國家表演藝術中心三館共製'
      },
      {
        img: require('./img/2-2.png'),
        webp: require('./img/2-2.webp'),
        name: '明華園戲劇總團《龍城爭霸》',
        by: '國家表演藝術中心衛武營國家藝術文化中心'
      },
      {
        img: require('./img/2-3.png'),
        webp: require('./img/2-3.webp'),
        name: '2019青春搖滾京劇\n《水滸108III─蕩寇誌》',
        by: '當代傳奇劇場'
      },
      {
        img: require('./img/2-4.png'),
        webp: require('./img/2-4.webp'),
        name: '明華園戲劇總團《大河彈劍》',
        by: '國家表演藝術中心國家兩廳院\n明華園戲劇總團'
      },
      {
        img: require('./img/2-5.png'),
        webp: require('./img/2-5.webp'),
        name: '《紅塵觀音-夜琴郎》',
        by: '尚和歌仔戲劇團'
      },
      {
        img: require('./img/2-6.png'),
        webp: require('./img/2-6.webp'),
        name: '清宮大戲《夢紅樓～乾隆與和珅》',
        by: '國家表演藝術中心國家兩廳院、國立傳統藝術中心'
      },
      {
        img: require('./img/2-7.png'),
        webp: require('./img/2-7.webp'),
        name: '唐美雲歌仔戲團2019年度新戲\n《千年渡‧白蛇》',
        by: '唐美雲歌仔戲團'
      },
      {
        img: require('./img/2-8.png'),
        webp: require('./img/2-8.webp'),
        name: '國光劇團 新編京劇《十八羅漢圖》',
        by: '國立傳統藝術中心、國家表演藝術中心國家兩廳院'
      },
      {
        img: require('./img/2-9.png'),
        webp: require('./img/2-9.webp'),
        name: '2019臺灣戲曲藝術節：一心戲劇團\n《當迷霧漸散》',
        by: '國立傳統藝術中心'
      },
      {
        img: require('./img/2-10.png'),
        webp: require('./img/2-10.webp'),
        name: 'NTT遇見巨人X國光X簡文彬ESO\n《快雪時晴》',
        by: '國家表演藝術中心臺中國家歌劇院\n國立傳統藝術中心'
      }
    ]
  },
  {
    name: '音樂',
    list: [
      {
        img: require('./img/3-1.png'),
        webp: require('./img/3-1.webp'),
        name: '普契尼歌劇《杜蘭朵》',
        by: '國家表演藝術中心衛武營國家藝術文化中心\n德國萊茵歌劇院共同製作'
      },
      {
        img: require('./img/3-2.png'),
        webp: require('./img/3-2.webp'),
        name: '莫札特歌劇《魔笛》',
        by: '國家表演藝術中心衛武營國家藝術文化中心'
      },
      {
        img: require('./img/3-3.png'),
        webp: require('./img/3-3.webp'),
        name: '朱宗慶打擊樂團 擊樂劇場《泥巴》',
        by: '財團法人擊樂文教基金會'
      },
      {
        img: require('./img/3-4.png'),
        webp: require('./img/3-4.webp'),
        name: '音契敘事樂篇《生命與愛的河流》',
        by: '音契文化藝術基金會'
      },
      {
        img: require('./img/3-5.png'),
        webp: require('./img/3-5.webp'),
        name: '尤洛夫斯基與倫敦愛樂管絃樂團',
        by: '國家表演藝術中心國家兩廳院、衛武營國家藝術文化中心',
        props: { mr: '-2em' },
      },
      {
        img: require('./img/3-6.png'),
        webp: require('./img/3-6.webp'),
        name: '帕佛賈維與阿姆斯特丹皇家大會館管弦樂團',
        by: '國家表演藝術中心國家兩廳院、臺中國家歌劇院'
      },
      {
        img: require('./img/3-7.png'),
        webp: require('./img/3-7.webp'),
        name: '《E.T.外星人》草地音樂會',
        by: '高雄市政府文化局、高雄市愛樂基金會'
      },
      {
        img: require('./img/3-8.png'),
        webp: require('./img/3-8.webp'),
        name: '2019臺灣國際打擊樂節《無懈可擊》',
        by: '財團法人擊樂文教基金會'
      },
      {
        img: require('./img/3-9.png'),
        webp: require('./img/3-9.webp'),
        name: '《新天堂樂園》草地音樂會',
        by: '高雄市文化局、財團法人高雄愛樂文化藝術基金會'
      },
      {
        img: require('./img/3-10.png'),
        webp: require('./img/3-10.webp'),
        name: '華格納歌劇《諸神黃昏》',
        by: '國家表演藝術中心臺中國家歌劇院'
      }
    ]
  },
  {
    name: '舞蹈',
    list: [
      {
        img: require('./img/4-1.png'),
        webp: require('./img/4-1.webp'),
        name: '雲門舞集陶身体劇場─\n林懷民、陶冶、鄭宗龍',
        by: '國家表演藝術中心三館共製、財團法人雲門文化藝術基金會'
      },
      {
        img: require('./img/4-2.png'),
        webp: require('./img/4-2.webp'),
        name: '鄭宗龍 X 雲門2《毛月亮》',
        by: '國家表演藝術中心三館共製'
      },
      {
        img: require('./img/4-3.png'),
        webp: require('./img/4-3.webp'),
        name: '荷蘭舞蹈劇場NDT 四舞作',
        by: '國家表演藝術中心臺中國家歌劇院'
      },
      {
        img: require('./img/4-4.png'),
        webp: require('./img/4-4.webp'),
        name: '德國萊茵芭蕾舞團《馬勒第七號》',
        by: '國家表演藝術中心臺中國家歌劇院、衛武營國家藝術文化中心、臺北市立交響樂團'
      },
      {
        img: require('./img/4-5.png'),
        webp: require('./img/4-5.webp'),
        name: '阿喀郎 ‧ 汗舞團《陌生人》',
        by: '國家表演藝術中心國家兩廳院、臺中國家歌劇院、\n財團法人辜公亮文教基金會'
      },
      {
        img: require('./img/4-6.png'),
        webp: require('./img/4-6.webp'),
        name: '2019焦點舞團《朵》',
        by: '焦點舞團'
      },
      {
        img: require('./img/4-7.png'),
        webp: require('./img/4-7.webp'),
        name: '2019年度製作《關於柴可夫斯基》',
        by: '福爾摩沙芭蕾舞團'
      },
      {
        img: require('./img/4-8.png'),
        webp: require('./img/4-8.webp'),
        name: '黃翊工作室+《長路》',
        by: '國家表演藝術中心三館共製'
      },
      {
        img: require('./img/4-9.png'),
        webp: require('./img/4-9.webp'),
        name: '林美虹x奧地利林茲國家劇院舞團\n《新娘妝》',
        by: '國家表演藝術中心衛武營國家藝術文化中心'
      },
      {
        img: require('./img/4-10.png'),
        webp: require('./img/4-10.webp'),
        name: '高雄市左營高中舞蹈班 108年舞展',
        by: '高雄市立左營高級中學'
      }
    ]
  },
  {
    name: '中型演出',
    note: '可售票數200~799張',
    list: [
      {
        img: require('./img/5-1.png'),
        webp: require('./img/5-1.webp'),
        name: '楊景翔演劇團《單身租隊友》',
        by: '楊景翔演劇團、齊立有限公司'
      },
      {
        img: require('./img/5-2.png'),
        webp: require('./img/5-2.webp'),
        name: '阮劇團X流山兒★事務所《嫁妝一牛車》',
        by: '阮劇團'
      },
      {
        img: require('./img/5-3.png'),
        webp: require('./img/5-3.webp'),
        name: '【朱宗慶打擊樂團２】豆莢寶寶兒童音樂會《淘氣旅行箱》',
        by: '財團法人擊樂文教基金會'
      },
      {
        img: require('./img/5-4.png'),
        webp: require('./img/5-4.webp'),
        name: '《三人行不行》狂熱加演場',
        by: '亮棠文創有限公司'
      },
      {
        img: require('./img/5-5.png'),
        webp: require('./img/5-5.webp'),
        name: '明池水劇場：優人神鼓《聽海之心》',
        by: '力麗觀光連鎖飯店集團'
      },
      {
        img: require('./img/5-6.png'),
        webp: require('./img/5-6.webp'),
        name: '華文原創音樂劇《妳的側臉》',
        by: '五口創意工作室、刺點創作工坊'
      },
      {
        img: require('./img/5-7.png'),
        webp: require('./img/5-7.webp'),
        name: '《莊子兵法》人性遊戲 4度玩命',
        by: '故事工廠 X 夢田文創 '
      },
      {
        img: require('./img/5-8.png'),
        webp: require('./img/5-8.webp'),
        name: '澎恰恰×躍演原創音樂劇\n《麗晶卡拉OK的最後一夜》',
        by: '躍演VMTheatre Company'
      },
      {
        img: require('./img/5-9.png'),
        webp: require('./img/5-9.webp'),
        name: '重現劇團《在遺忘之後》',
        by: '國家表演藝術中心臺中國家歌劇院'
      },
      {
        img: require('./img/5-10.png'),
        webp: require('./img/5-10.webp'),
        name: '2019春天戲水《K24》青春正鬧版 ',
        by: '台南人劇團'
      }
    ]
  },
  {
    name: '小型演出',
    note: '可售票數199張以下',
    list: [
      {
        img: require('./img/6-1.png'),
        webp: require('./img/6-1.webp'),
        name: '《職男人生之全面起笑》',
        by: '面白大丈夫劇團、威秀影城'
      },
      {
        img: require('./img/6-2.png'),
        webp: require('./img/6-2.webp'),
        name: '臺北兒童藝術節《拇指小英雄》',
        by: '臺北市政府'
      },
      {
        img: require('./img/6-3.png'),
        webp: require('./img/6-3.webp'),
        name: '小乳牛劇團《披頭四－寶寶音樂會》',
        by: '國家表演藝術中心衛武營國家藝術文化中心'
      },
      {
        img: require('./img/6-4.png'),
        webp: require('./img/6-4.webp'),
        name: '獨角音樂劇《焢肉遇見你》',
        by: 'C MUSICAL製作'
      },
      {
        img: require('./img/6-5.png'),
        webp: require('./img/6-5.webp'),
        name: '國立臺北藝術大學戲劇學院2019春季公演\n《西藏度亡經》《Trade Trade Love愛-換-換》',
        by: '國立臺北藝術大學',
        props: { mr: '-3em' },
      },
      {
        img: require('./img/6-6.png'),
        webp: require('./img/6-6.webp'),
        name: '《無眠夜的微光》',
        by: '河床劇團'
      },
      {
        img: require('./img/6-7.png'),
        webp: require('./img/6-7.webp'),
        name: '《地球人遇見小王子》',
        by: '躍演'
      },
      {
        img: require('./img/6-8.png'),
        webp: require('./img/6-8.webp'),
        name: '《拿破輪的奇幻冒險》',
        by: '玉米雞劇團'
      },
      {
        img: require('./img/6-9.png'),
        webp: require('./img/6-9.webp'),
        name: '臺北兒童藝術節《大腳小小鳥》',
        by: '臺北市政府'
      },
      {
        img: require('./img/6-10.png'),
        webp: require('./img/6-10.webp'),
        name: '國立臺北藝術大學戲劇學院2019夏季公演《物種起源》',
        by: '國立臺北藝術大學'
      }
    ]
  }
]
