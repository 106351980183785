import React, { createElement } from 'react';

import Box from '../../../components/Box'
import Text from '../../../components/Text'
import Image from '../../../components/Image'
import Container from '../../../components/Container'
import DotsBg from '../../../components/DotsBg'
import NumberAndText from '../../../components/NumberAndText'
import RoundTitleLabel from '../../../components/RoundTitleLabel'
import LineChart from '../../../components/Charts/LineChart'
import TickLabel from '../../../components/Charts/TickLabel'
import { schemas } from '../../../components/ThemeProvider/theme'

import useCSV from '../../../utils/useCSV'
import getYtickLabel from '../../getYtickLabel'

import bg from './bg.svg'
import coin from './coin.svg'
import ticket from './ticket.svg'
import frame from './frame.svg'

import csv from './p9.csv'

const keys = ['戲劇', '舞蹈', '音樂', '親子']

const Page = ({ resizeRatio, activated, containerWidth, em, toActive }) => {
  const { data } = useCSV(csv, toActive)
  return (
    <Box height="100%" position="relative" bg="orange">
      <Container position="relative" height="100%" width={containerWidth}>
        <Image data-src={bg} />
        <Box top="3em" left="11.75em" position="absolute">
          <Text.h2 color="primary">決定購票的價位</Text.h2>
        </Box>
        <Box
          position="absolute"
          style={{
            top: 717 * resizeRatio,
            left: 250 * resizeRatio,
          }}
        >
          <NumberAndText color="white">註：中位數係指將所有數值排序後，位於50%處的數值，相較於平均數，中位數較不受極端值影響。</NumberAndText>
        </Box>
        <DotsBg
          position="absolute"
          style={{
            width: 914 * resizeRatio,
            top: 207 * resizeRatio,
            left: 280 * resizeRatio,
          }}
        >
          <Image data-src={frame} />
          <Box position="absolute" top="0" left="2.75em" right="2.75em" bottom="0">
            <RoundTitleLabel mt="2.5em">2013-2019年 各年齡層購買票價中位數</RoundTitleLabel>
            <Box position="absolute" top="8.5em" left="1em" right="0" bottom="1.5em">
              {data && (
                <LineChart
                  activated={activated}
                  width={810 * resizeRatio}
                  height={310 * resizeRatio}
                  data={data}
                  xAccessor={d => d['年齡']}
                  keys={keys}
                  xTick={{
                    label: '（歲）',
                    tickComponent: ({ formattedValue, ...tickProps }) => createElement(TickLabel, { ...tickProps, y: '1.375em', dx: '-1em' }, formattedValue),
                  }}
                  yTick={{
                    label: getYtickLabel('購買票價中位數', '元'),
                    tickValues: [0, 225, 450, 675, 900],
                  }}
                  margin={{
                    top: 1,
                    left: 6,
                    right: 5,
                  }}
                  colors={keys.map(key => schemas.show[key])}
                  em={em}
                />
              )}
            </Box>
          </Box>
        </DotsBg>
        <Box
          position="absolute"
          style={{
            top: 511 * resizeRatio,
            left: 82 * resizeRatio,
            width: 282 * resizeRatio,
          }}
        >
          <Image data-src={ticket} />
          <Box position="absolute" top="2.5em" left="3em" right="3.75em">
            <Text.h3 fontSize="2.125em">表演類型</Text.h3>
            <Text.h3 fontSize="1.625em" lineHeight="1.375">是票價差異的主要原因</Text.h3>
          </Box>
        </Box>
      </Container>
      <Box
        position="absolute"
        style={{
          top: -126 * resizeRatio,
          right: -48 * resizeRatio,
          width: 355 * resizeRatio,
        }}
      >
        <Image data-src={coin} />
      </Box>
    </Box>
  );
};

export default Page;
