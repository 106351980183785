import React from 'react'

import NumberAndText from '../NumberAndText'

const TickLabel = (props) => (
  <NumberAndText
    {...props}
    is="text"
    inlineIs="tspan"
    respectText={false}
    fontWeight="bold"
    noResize
  />
)

export default TickLabel
