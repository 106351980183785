import range from 'lodash/range';
import last from 'lodash/last';

import memorize from '../../utils/memorize'

const baseFontSize = 16
// const emToPx = (em) => `${em * baseFontSize}px`;

export const breakpoints = [
  // 640,
  '1024px',
];
export const containerWidth = 1600
export const responsiveIndex = [
  1, // mobile
  // 4, // laptop
]

export const responsive = memorize((...args) => breakpoints.map((_, i) => {
  if (args.length <= 1) return args[0]
  let r = last(args)
  let index = 0
  while (r === last(args) && index < responsiveIndex.length) {
    if (i < responsiveIndex[index]) r = args[index]
    index += 1
  }
  return r
}));

export const mobileOrDesktop = responsive

const font = '"Baloo Bhai 2", "Noto Sans TC", "PingFang TC", "HeiTi TC", "Microsoft JhengHei", sans-serif';

const yellow = '#FCC013';
const white = '#fff';
const black = '#000';
const lightBlack = '#333333';
const orange = '#B05232';
const coffee = '#8E452D';

export const schemas = {
  show: {
    戲劇: '#FFD377',
    音樂: '#9FAAC4',
    舞蹈: '#E8A390',
    親子: '#4DA58E',
  },
  showWithOther: {
    戲劇: '#FFD377',
    音樂: '#9FAAC4',
    舞蹈: '#E8A390',
    親子: '#4DA58E',
    其他: '#d3bccb',
  },
  purples: [
    '#a75fab',
    '#9D6A8F',
    '#CEB5C7',
  ],
}

export default {
  colors: {
    white,
    black,
    orange,
    coffee,
    yellow,
    lightBlack,
    text: black,
    primary: yellow,
    primaryHover: yellow,
  },
  breakpoints,
  containerWidth,
  font,
  fontSize: `${baseFontSize}px`,
  responsive,
  zOrder: range(4).map((i) => 10 ** i),
};
