import React, { createElement } from 'react';
import styled from 'styled-components';
import groupBy from 'lodash/groupBy'

import Container from '../../../components/Container'
import Box from '../../../components/Box'
import Flex from '../../../components/Flex'
import DotsBg from '../../../components/DotsBg'
import Text from '../../../components/Text'
import Icons from '../../../components/Icons'
import Image from '../../../components/Image'
import BackgroundImage from '../../../components/BackgroundImage'
import RoundTitleLabel from '../../../components/RoundTitleLabel'

import useCSV from '../../../utils/useCSV'
import csv from './p15.csv'

import up from './up.svg'
import down from './down.svg'
import no from './no.svg'

import n from './n.svg'
import nn from './nn.svg'
import c from './c.svg'
import s from './s.svg'
import pattern from './pattern.svg'
import dots from './dots.svg'

const Table = styled.table`
border-collapse: collapse;
border-spacing: 0;
flex-grow: 1;

thead th {
  padding: 0.375em 0 0.25em;
  font-size: 1.375em;
}
tbody th {
  font-size: 1.5em;
  width: 9em;
}
tbody td {
  font-size: 1.125em;
  padding: 0.25em 1em 0;
  line-height: 1;
}
`

const TheIcon = props => <Box textAlign="center"><Icons fontSize="1.25em" {...props} /></Box>

const types = {
  戲劇: 'play',
  音樂: 'music',
  舞蹈: 'dance',
  親子: 'family',
}

const maps = {
  雙北: n,
  北部: nn,
  中部: c,
  南部: s,
}

const Page = ({ resizeRatio, containerWidth, toActive }) => {
  const { data } = useCSV(csv, toActive)
  const dataSizes = groupBy(data, '地區別')
  const keys = data && Object.keys(data[0])
  let nowKey
  let firstRegion
  return (
    <DotsBg height="100%">
      <Container pt="2em" px="10em" pb="2em" width={containerWidth}>
        <Text.h2 textAlign="center" color="primary">全台的表演市場正在改變</Text.h2>
        <Text.h4 mt="0.25em" mb="0.5em" textAlign="center" color="white">各地區皆有明顯正成長的節目類別</Text.h4>
        <RoundTitleLabel mb="1.5em">2013-2019年 各地區表演成長趨勢表</RoundTitleLabel>
        <Box bg="#f2e6d4" position="relative">
          <Box position="absolute" bottom="0" right="1em" width={140 * resizeRatio}>
            <Image data-src={pattern} />
          </Box>
          {data && (
            <Flex>
              <Table>
                <thead>
                  <tr>
                    {keys.map((k, i) => (
                      <th
                        key={`h${k}`}
                        style={{
                          backgroundColor: i ? '#e6cfb0' : '#b05232',
                          color: !i && 'white',
                          borderRight: (i === 1 || i === keys.length - 1) && '3px solid white',
                          paddingLeft: i > 1 && '0.5em',
                          paddingRight: i > 1 && '0.5em',
                        }}
                      >
                        {i > 1 && createElement(Icons[types[k]], { width: '1.125em', mr: '0.25em', verticalAlign: 'text-top' })}
                        {k}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {data.map((d, i) => (
                    <tr key={`row-${i}`}>
                      {keys.map((k, j) => {
                        if (j) {
                          return (
                            <td
                              key={`row-${i}-col${k}`}
                              style={{
                                borderTop: firstRegion ? '3px solid white' : '1px dashed white',
                                borderRight: (j === 1 || j === keys.length - 1) && '3px solid white',
                              }}
                            >
                              {d[k] ? (
                                d[k] === '+' ? <TheIcon src={up} /> : (
                                  d[k] === '-' ? <TheIcon src={down} /> : d[k]
                                )
                              ) : <TheIcon src={no} />}
                            </td>
                          )
                        } else {
                          firstRegion = nowKey !== d[k]
                          nowKey = d[k]
                          return firstRegion ? (
                            <th
                              key={`row-${i}-col${k}`}
                              rowSpan={dataSizes[d[k]].length}
                              style={{ backgroundColor: '#b05232', color: 'white', borderTop: '2px solid rgba(255,255,255,0.2)' }}
                            >
                              <Icons src={maps[d[k]]} width="2.875em" mr="0.5em" />
                              <Box.inline>
                                {d[k] === '北部' ? (
                                  <>{d[k]}<Text fontSize="1em">非雙北</Text></>
                                ): d[k]}
                              </Box.inline>
                            </th>
                          ) : null
                        }
                      })}
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Flex width="8.75em" px="0.5em" flexDirection="column" justifyContent="center">
                <Text>經統計簡單線性迴歸分析呈顯著</Text>
                <Box mt="2.25em">
                  <Box my="0.5em">
                    <Icons mr="0.5em" src={up} />趨勢上升
                  </Box>
                  <Box my="0.5em">
                    <Icons mr="0.5em" src={down} />趨勢下降
                  </Box>
                  <Box my="0.5em">
                    <Icons mr="0.5em" src={no} />無明顯趨勢
                  </Box>
                </Box>
              </Flex>
              <Box width="3.625em"></Box>
            </Flex>
          )}
          <Box bg="white" pt="0.75em" pb="0.5em" px="1em" position="relative">
            註：東部地區與其他地區數值相較落差較大，故不放入比較圖表，惟仍有顯示票房、場次、購票數數值。
          </Box>
          <BackgroundImage
            position="absolute"
            right="0"
            top="0"
            height="100%"
            width="3.625em"
            src={dots}
            backgroundRepeat="repeat"
            backgroundSize="100%"
            backgroundPosition="0 0"
          />
        </Box>
      </Container>
    </DotsBg>
  );
};

export default Page;
