import React from 'react';

import DotsBg from '../../../components/DotsBg'
import Box from '../../../components/Box'

const Transition = ({ resizeRatio }) => {
  const height = 136 * resizeRatio
  return (
    <DotsBg
      style={{
        marginBottom: -70 * resizeRatio,
        height,
      }}
    >
      <Box position="relative" zIndex={1}>
        <svg width={window.innerWidth} height={height}>
          <polygon
            points={[
              [0, 0],
              [window.innerWidth, 0],
              [(window.innerWidth / 2) + (526 * resizeRatio), height],
              [(window.innerWidth / 2) - (534 * resizeRatio), height],
              [0, 0],
            ].map(d => d.join()).join(' ')}
            fill="#8e452d"
            stroke="black"
            strokeWidth="0.125em"
          />
        </svg>
      </Box>
    </DotsBg>
  );
};

export default Transition;
