import React from 'react';
import { format } from 'd3-format'

import frame from './frame.svg'
import left from './left.svg'
import csv from './p12.csv'

import FullLeft from '../../FullLeft';

import Box from '../../../components/Box'
import NumberAndText from '../../../components/NumberAndText'
import Container from '../../../components/Container'
import Text from '../../../components/Text'
import Image from '../../../components/Image'
import RoundTitleLabel from '../../../components/RoundTitleLabel'
import StackedBars from '../../../components/Charts/StackedBars'
import { schemas } from '../../../components/ThemeProvider/theme'
import getYtickLabel from '../../getYtickLabel'

import useCSV from '../../../utils/useCSV'

const Page = ({ activated, resizeRatio, containerWidth, em, isMobile, toActive }) => {
  const { data } = useCSV(csv, toActive)
  return (
    <Box height="100%" position="relative" bg="orange">
       <Box
        position="absolute"
        right="0"
        width="7.625em"
        top="0"
        height="100%"
        borderLeft="0.1em solid black"
      />
      <Box
        position="absolute"
        left="0"
        width="7.625em"
        top="0"
        height="100%"
        borderRight="0.1em solid black"
      />
      <Container position="relative" width={containerWidth}>
        <Box textAlign="center" color="white" position="absolute" top="4em"  left="50%" transform="translateX(-50%)" width={1}>
          <Text.h2 color="yellow" my="0.125em">要跟誰一起看表演呢?</Text.h2>
          <NumberAndText fontSize="1.625em">單次購買1張票的比例，有隨年齡微幅下降再上升的趨勢</NumberAndText>
          <NumberAndText fontSize="1.625em">其中，35-44歲購票張數轉變為3張以上</NumberAndText>
        </Box>
        <Image data-src={frame} />
        <Box position="absolute" top="13.5em" left="17em" right="13em" bottom="4em">
          <RoundTitleLabel ml="2em" mt="3.75em">2013-2019年 各年齡層單次購票張數人數佔比</RoundTitleLabel>
          <Box position="absolute" top="10em" left="0" right="0" bottom="3.375em">
            {data && (
              <StackedBars
                activated={activated}
                width={856 * resizeRatio}
                height={310 * resizeRatio}
                data={data}
                xAccessor={d => d['年齡']}
                keys={Object.keys(data[0]).filter(k => k !== '年齡')}
                xTick={{ label: '（歲）' }}
                yTick={{
                  label: getYtickLabel('購票人數佔比', '％'),
                  tickFormat: d => String(d * 100),
                  tickValues: [0, 0.25, 0.50, 0.75, 1],
                }}
                valueFormat={format('.0%')}
                tooltipFormat={format('.0%')}
                margin={{
                  top: 1,
                  right: 8.5,
                  bottom: 2.25,
                  left: 5,
                }}
                colors={schemas.purples}
                em={em}
                isMobile={isMobile}
              />
            )}
          </Box>
        </Box>
      </Container>
      <FullLeft src={left} containerWidth={containerWidth} />
    </Box>
  );
};

export default Page;
