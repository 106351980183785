import React from 'react';
import styled, { css } from 'styled-components';
import {
  typography,
  space,
  color,
  layout,
  border,
  borderRadius,
  position,
} from 'styled-system';
import themeGet from '@styled-system/theme-get';
import tag from 'clean-tag';

import Box from './Box';

import { customColor } from './utils/getColor';
import blacklist from './utils/blacklist';
import injectProps from './utils/injectProps';

const active = css`
  ${customColor('hoverColor')};
  ${customColor('hoverBg', 'backgroundColor')};
  ${customColor('hoverBorder', 'borderColor')};
`;

export const buttonStyle = css`
  padding: 0;
  border: none;
  font-family: inherit;
  line-height: 1;
  text-decoration: none;
  ${typography}
  ${layout}
  ${position}
  ${space}
  ${color}
  ${border}
  ${borderRadius}
  ${injectProps('transform')}
  appearance: none;
  transition: all ${themeGet('duration', 250)}ms;
  cursor: pointer;
  &:hover,
  &:focus {
    ${props => !props.disabled && active}
    outline: none;
  }
  ${props => props.active && !props.disabled && active}
  ${props =>
    props.disabled &&
    `
    cursor: not-allowed;
    opacity: 0.5;
  `}
`;

const ButtonBase = styled(tag)`
  ${buttonStyle};
`;

const InButtonSpan = props => <Box is="span" {...props} />;

const Button = ({
  leftIcon,
  rightIcon,
  iconSpacing,
  children,
  verticalAlign,
  ...props
}) => (
  <ButtonBase {...props}>
    {leftIcon ? (
      <Box is={leftIcon} mr={iconSpacing} verticalAlign={verticalAlign} />
    ) : null}
    <InButtonSpan verticalAlign={verticalAlign}>{children}</InButtonSpan>
    {rightIcon ? (
      <Box is={rightIcon} ml={iconSpacing} verticalAlign={verticalAlign} />
    ) : null}
  </ButtonBase>
);

Button.defaultProps = {
  blacklist,
  is: 'button',
  border: '0.125em solid',
  borderColor: 'black',
  bg: 'white',
  color: 'text',
  hoverColor: 'text',
  hoverBg: 'primaryHover',
  hoverBorder: 'black',
  px: '1em',
  pt: '0.5em',
  pb: '0.375em',
  fontWeight: 'bold',
  iconSpacing: '0.25em',
  borderRadius: '2em',
  display: 'inline-block',
  verticalAlign: 'middle',
  fontSize: '1.25em',
  textAlign: 'center',
};

Button.displayName = 'Button';

Button.dark = props => (
  <Button
    bg="black"
    color="white"
    hoverBg="orange"
    hoverColor="white"
    {...props}
  />
)

Button.circle = props => (
  <Button
    borderRadius="50%"
    px="0em"
    pt="0"
    pb="0"
    {...props}
  />
);

Button.share = props => (
  <Button
    border="2px solid"
    borderRadius="50%"
    px="0em"
    pt="0"
    pb="0"
    bg="black"
    color="white"
    hoverColor="white"
    hoverBg="orange"
    hoverBorder="orange"
    display="inline-block"
    {...props}
  />
);

export default Button;
