import React, { Fragment } from 'react';
import { Bar } from '@vx/shape';
import { Group } from '@vx/group';
import { AxisLeft } from '@vx/axis';
import { scaleBand, scaleLinear } from '@vx/scale';
import mapValues from 'lodash/mapValues'
import { NodeGroup } from 'react-move'

import Box from '../../../components/Box'
import DotsBg from '../../../components/DotsBg'
import theme from '../../../components/ThemeProvider/theme'
import NumberAndText from '../../../components/NumberAndText'

import tweenConfig from '../../../components/Charts/tweenConfig'

import useResponsive from '../../../contexts/mediaQuery/useResponsive'
import Coin from './Coin'

const xAccessor = d => d['占比']
const yAccessor = d => d['金額']

const margin = {
  top: 6.75,
  right: 11.25,
  bottom: 3.125,
  left: 9,
}

const CoinBarChart = ({
  width,
  height,
  data,
  activated,
  em,
}) => {
  if (isNaN(em)) return null
  const frameBorderWidth = 0.125 * em
  const emedMargin = mapValues(margin, d => d * em)
  // scales
  const yScale = scaleBand({
    domain: data.map(yAccessor),
    padding: 0.33,
  });
  const xScale = scaleLinear({
    domain: [0, 50],
    nice: true,
  });
  // bounds
  const xMax = width - emedMargin.left - emedMargin.right;
  const yMax = height - emedMargin.top - emedMargin.bottom;
  const frameWidth = 4 * em

  xScale.range([0, xMax]);
  yScale.rangeRound([0, yMax]);
  return (
    <Box height="100%" position="relative">
      <DotsBg position="absolute" left={`${margin.left}em`} right="0" top="0" bottom="0" />
      <svg width={width} height={height} style={{ position: 'relative' }}>
        <rect
          x={emedMargin.left + frameBorderWidth / 2}
          y={frameBorderWidth / 2}
          width={width - emedMargin.left - frameBorderWidth}
          height={frameWidth}
          fill="#5e2a15"
          stroke="black"
          strokeWidth={frameBorderWidth}
        />
        <polygon
          points={[
            [width - frameBorderWidth / 2 - frameWidth, frameWidth],
            [width - frameBorderWidth / 2,  frameBorderWidth / 2],
            [width - frameBorderWidth / 2, height - frameBorderWidth / 2],
            [width - frameBorderWidth / 2 - frameWidth, height - frameBorderWidth / 2],
          ].map(p => p.join()).join(' ')}
          fill="#8e452d"
          stroke="black"
          strokeWidth={frameBorderWidth / 2}
        />
        <AxisLeft
          top={emedMargin.top}
          left={emedMargin.left}
          scale={yScale}
          hideAxisLine
          hideTicks
          tickLabelProps={() => ({
            fill: '#fff',
            textAnchor: 'end',
            fontSize: '1.125em',
            dx: '-0.25em',
            dy: '0.25em'
          })}
        />
        {activated && (
          <NodeGroup
            data={data}
            keyAccessor={yAccessor}
            start={() => ({
              width: 0,
            })}
            enter={(d) => ({
              width: [xScale(xAccessor(d))],
              timing: tweenConfig,
            })}
            update={(d) => ({
              width: [xScale(xAccessor(d))],
              timing: tweenConfig,
            })}
          >
            {nodes => (
              <Group top={emedMargin.top} left={emedMargin.left}>
                {nodes.map(({ data: d, state, key: dollar }) => {
                  const count = xAccessor(d)
                  const barHeight = yScale.bandwidth();
                  const barWidth = state.width
                  const barY = yScale(dollar);
                  const coinSize = barHeight * 1.1
                  return (
                    <Fragment key={`bar-${dollar}`}>
                      <Bar
                        x={frameBorderWidth / 2}
                        y={barY}
                        width={barWidth}
                        height={barHeight}
                        fill={theme.colors.primary}
                        stroke="black"
                        strokeWidth={frameBorderWidth}
                      />
                      <Coin left={barWidth - coinSize / 2} top={barY - (coinSize - barHeight) / 2} size={coinSize} />
                      <text
                        x={barWidth + coinSize / 2 + 0.5 * em}
                        y={barY + barHeight / 2}
                        dy="0.25em"
                        fontSize="1.5em"
                        fill={theme.colors.primary}
                      >{count}%</text>
                    </Fragment>
                  );
                })}
              </Group>
            )}
          </NodeGroup>
        )}
      </svg>
      <Box position="absolute" left="10.75em" top="1.25em">
        <NumberAndText is="h5" color="white" fontSize="1.5em" lineHeight="1">2013-2019年 購票會員年度消費金額分佈</NumberAndText>
      </Box>
    </Box>
  );
};

export default CoinBarChart;
