import React from 'react';

import Box from '../components/Box'
import Container from '../components/Container'
import Image from '../components/Image'

const FullRight = ({ src, children, containerWidth, ...props }) => {
  return (
    <Box
      position="absolute"
      top="0"
      bottom="0"
      left="0"
      right="0"
      pointerEvents="none"
      {...props}
    >
      <Container mx="0" ml="auto" mr="0" width={containerWidth}>
        <Image data-src={src} />
        {children}
      </Container>
    </Box>
  );
};

export default FullRight;
