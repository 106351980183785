import React from 'react'
import SVG from './SVG'

const Click = () => {
  return (
    <SVG viewBox="0 0 18.83 18.83" width="1em">
      <rect x="0.5" y="0.5" width="17.83" height="17.83" fill="none" stroke="#fff"/>
    </SVG>
  )
}

export default Click
