import React from 'react'
import styled from 'styled-components'

import Box from './Box'
import Click from './Click'
import Rect from './Rect'

const Input = styled.input`
opacity: 0;
position: absolute;
visibility: hidden;
`

const Checkbox = ({
  checked,
  name,
  onChange = () => {},
  ...props
}) => {
  return (
    <Box lineHeight="1" width="1em" {...props}>
      <Input
        type="checkbox"
        name={name}
        checked={checked}
        onChange={onChange}
      />
      {checked ? <Click /> : <Rect />}
    </Box>
  )
}

export default Checkbox
