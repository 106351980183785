import React from 'react';

import Box from '../../../components/Box'
import Container from '../../../components/Container'
import Text from '../../../components/Text'
import NumberAndText from '../../../components/NumberAndText'
import Image from '../../../components/Image'
import FullRight from '../../FullRight'
import browser from '../../../components/utils/browser'

import right from './right.svg'
import stage from './stage.svg'
import ticket from './ticket.svg'

const isMobile = browser.getPlatformType() !== 'desktop'

const Page = ({ resizeRatio, containerWidth }) => {
  return (
    <Box height="100%" position="relative" bg="orange">
      <Box
        position="absolute"
        top={0}
        bottom={0}
        style={{
          top: 0,
          bottom: 0,
          left: 224 * resizeRatio,
        }}
        borderLeft="0.125em solid black"
      />
      <Box
        position="absolute"
        style={{
          top: 0,
          bottom: 0,
          right: 224 * resizeRatio,
        }}
        borderLeft="0.125em solid black"
      />
      <Box
        position="absolute"
        style={{
          width: 434.39 * resizeRatio,
          left: 144 * resizeRatio,
          top: 313.44 * resizeRatio,
        }}
      >
        <Image data-src={ticket} />
        <Box
          position="absolute"
          textAlign="center"
          lineHeight="1"
          style={{
            width: 202 * resizeRatio,
            left: 99.05 * resizeRatio,
            top: 25.45 * resizeRatio,
          }}
        >
          <Box fontSize="6em">197<Text.inline fontSize="33.33%">萬</Text.inline></Box>
          <Box fontSize="2em">售票張數</Box>
        </Box>
      </Box>
      <FullRight src={right} containerWidth={containerWidth}>
        <Box
          position="absolute"
          textAlign="center"
          lineHeight="1"
          style={{
            width: 243.45 * resizeRatio,
            right: 175.4 * resizeRatio,
            top: 317 * resizeRatio,
          }}>
          <Box fontSize="6em">13.6<Text.inline fontSize="33.33%">億</Text.inline></Box>
          <Box fontSize="2em">節目票房</Box>
        </Box>
      </FullRight>
      <Container pt="3.75em" position="relative" height="100%" width={containerWidth}>
        <Box.inline
          fontWeight="bold"
          borderTop="0.625em solid"
          borderColor="coffee"
          bg="black"
          py="1.5em"
          px="2em"
          color="yellow"
          style={{
            marginLeft: 232 * resizeRatio
          }}
        >
          <Text.h2>表演藝術市場的格局展現</Text.h2>
        </Box.inline>
        <Box
          mt="0.75em"
          fontSize="1.5em"
          style={{
            marginLeft: 232 * resizeRatio
          }}
        >
          <Text color="white">不論票房、場次與售票張數，2019年都是歷年新高，</Text>
          <Text color="white">甚至超越了2013-2014年太陽劇團來台的歷史票房高點。</Text>
        </Box>
        <Box
          position="absolute"
          style={{
            width: 558 * resizeRatio,
            left: 475 * resizeRatio,
            top: 424 * resizeRatio,
          }}
        >
          <Image data-src={stage} />
          <Box
            position="absolute"
            color="white"
            textAlign="center"
            style={{
              left: (isMobile ? 127 : 137) * resizeRatio,
              top: 109 * resizeRatio,
            }}
          >
            <Box fontSize="6em" lineHeight="1" color="yellow">7,119<Text.inline fontSize="33.33%">場</Text.inline></Box>
            <Box fontSize="2em">演出場次</Box>
            <Box fontSize="1.25em">
              <NumberAndText>
              （平均每天超過19場演出）
              </NumberAndText>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Page;
