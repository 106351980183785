import React from 'react';
import { MdFileDownload } from 'react-icons/md';

import Box from '../../../components/Box'
import Flex from '../../../components/Flex'
import Text from '../../../components/Text'
import Image from '../../../components/Image'
import Link from '../../../components/Link'
import Button from '../../../components/Button'
import DotsBg from '../../../components/DotsBg'
import Container from '../../../components/Container';

import ShareButton from './ShareButton'
import bglogo from './bglogo.svg'

const info = [
  {
    text: ' 下載更完整的數據洞察\n《2013-2019年兩廳院售票系統消費行為報告》',
    href: './book/'
  },
  // {
  //   text: '下載2018年完整報告',
  //   href: 'https://artsticketreport.npac-ntch.org/2019/'
  // },
]

const Page = ({ resizeRatio, containerWidth }) => {
  return (
    <DotsBg height="100%">
      <Container pt="2.5em" position="relative" height="100%" width={containerWidth}>
        <Box position="absolute" left="0" right="0" top="0.5em">
          <Image data-src={bglogo} />
        </Box>
        <Box position="absolute" style={{ top: 190 * resizeRatio }} left="50%" transform="translateX(-50%)">
          <Text.h2 fontSize="2.25em">THANK YOU</Text.h2>
        </Box>
        <Box position="absolute" style={{ top: 347 * resizeRatio }} left="50%" transform="translateX(-50%)" whiteSpace="nowrap">
          <Text.h2 color="yellow">讓更多人遇見表演藝術的美好</Text.h2>
        </Box>
        <Box textAlign="center" color="white" fontSize="1.5em" position="absolute" style={{ top: 413 * resizeRatio }} left="50%" transform="translateX(-50%)">
          <Text>透過數據持續關注產業的發展動向</Text>
          <Text>推動表演在全台各地持續開花</Text>
        </Box>
        <Box whiteSpace="pre-wrap" color="white" position="absolute" width="100%" style={{ top: 556 * resizeRatio }} left="50%" transform="translateX(-50%)">
          <Flex justifyContent="center" alignItems="center">
            {info.map(({ text, href }, index) => (
              <Box mx="0.5em" key={index}>
                <Button.dark px="2.5em" py="1.25em" textAlign="left" lineHeight="1.5" is={Link} href={href}>
                  <Flex alignItems="center">
                    <Box>
                      <MdFileDownload size="3em" />
                    </Box>
                    <Box>
                      {text}
                    </Box>
                  </Flex>
                </Button.dark>
              </Box>
            ))}
          </Flex>
          <ShareButton width={1} mt="1em" fontSize="1.5em" />
        </Box>
      </Container>
    </DotsBg>
  );
};

export default Page;
