import React from 'react';

import Box from '../../../components/Box'
import Text from '../../../components/Text'
import Image from '../../../components/Image'
import Container from '../../../components/Container'
import NumberAndText from '../../../components/NumberAndText'

import CoinBarChart from './CoinBarChart'

import useCSV from '../../../utils/useCSV'

import bg from './bg.svg'
import hand from './hand.svg'
import csv from './p5.csv'

const Page = ({ resizeRatio, activated, containerWidth, em, toActive }) => {
  const { data } = useCSV(csv, toActive)
  return (
    <Box height="100%" position="relative" bg="orange">
      <Container position="relative" height="100%" width={containerWidth}>
        <Image data-src={bg} />
        <Box position="absolute" top="4em" left="0" right="0" textAlign="center">
          <Text.h2 color="primary" fontSize="2.75em">
          觀眾的支持力有很高的潛能
          </Text.h2>
          <NumberAndText is="h4" color="white" fontSize="1.5em">
          近四成的觀眾，平均年花費超過5,000元欣賞表演藝術
          </NumberAndText>
        </Box>
        <Box
          position="absolute"
          style={{
            width: 1080 * resizeRatio,
            top: 198 * resizeRatio,
            left: 142 * resizeRatio,
            height: 497 * resizeRatio,
          }}
        >
          {data && (
            <CoinBarChart
              width={1080 * resizeRatio}
              height={497 * resizeRatio}
              data={data}
              activated={activated}
              em={em}
            />
          )}
        </Box>
        <Box top="0" left="0" width={1} position="absolute" pointerEvents="none">
          <Image data-src={hand} />
        </Box>
      </Container>
    </Box>
  );
};

export default Page;
