import React from 'react';

import Container from '../../../components/Container'
import Image from '../../../components/Image'
import DotsBg from '../../../components/DotsBg'

import ticket from './ticket.svg'

const Transition = ({ resizeRatio, containerWidth }) => {
  return (
    <DotsBg
      style={{ marginBottom: -70 * resizeRatio }}
    >
      <Container
        mx="0"
        ml="auto"
        mr="0"
        position="relative"
        zIndex={1}
        width={containerWidth}
      >
        <Image src={ticket} />
      </Container>
    </DotsBg>
  );
};

export default Transition;
