import React from 'react';

import Box from '../../../components/Box'
import Container from '../../../components/Container'
import Image from '../../../components/Image'

import watch from './watch.svg'

const Transition = ({ resizeRatio, containerWidth }) => {
  return (
    <Box
      style={{
        marginTop: -10 * resizeRatio,
        marginBottom: -100 * resizeRatio,
      }}
      bg="orange"
    >
      <Container
        mx="0"
        ml="auto"
        mr="0"
        position="relative"
        zIndex={1}
        width={containerWidth}
      >
        <Image src={watch} />
      </Container>
    </Box>
  );
};

export default Transition;
