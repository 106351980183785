import React, { useState } from 'react';
import { LinePath, Bar, Line } from '@vx/shape';
import { Group } from '@vx/group';
import { AxisBottom, AxisLeft } from '@vx/axis';
import { scaleBand, scaleLinear } from '@vx/scale';
import { withTooltip } from '@vx/tooltip';
import { localPoint } from '@vx/event';
import mapValues from 'lodash/mapValues'
import merge from 'lodash/merge';
import { NodeGroup } from 'react-move'

import Text from '../Text'
import Box from '../Box'
import Flex from '../Flex'
import NumberAndText from '../NumberAndText'
import Button from '../Button'
import TooltipBox from './TooltipBox'
import theme from '../ThemeProvider/theme'
import { tickLeft, tickBottom } from './axisConfig'
import tweenConfig from './tweenConfig'

const TheLinePath = (props) => (
  <LinePath
    stroke="#4d4d4d"
    strokeWidth="0.25em"
    strokeLinecap="round"
    style={{ transition: 'stroke 750ms' }}
    {...props}
  />
)

const defaultMargin = {
  top: 6.875,
  left: 5,
  right: 4,
  bottom: 3,
}

let tooltipTimeout;
export default withTooltip(({
  data,
  xAccessor,
  keys,
  width,
  height,
  margin = {},
  tooltipOpen,
  tooltipLeft,
  tooltipTop,
  tooltipData,
  hideTooltip,
  showTooltip,
  xTick = {},
  yTick = {},
  valueFormat = v => v,
  em,
}) => {
  if (!data || !xAccessor || !keys) return null
  const x = typeof xAccessor === 'string' ? d => d[xAccessor] : xAccessor
  if (isNaN(em)) return null
  const [activeKey, updateActiveKey] = useState(keys[0])
  const emedMargin = mapValues(merge({}, defaultMargin, margin), d => d * em)
  // bounds
  const xMax = width - emedMargin.left - emedMargin.right;
  const yMax = height - emedMargin.top - emedMargin.bottom;

  // scales
  const xScale = scaleBand({
    domain: data.map(x),
    padding: 0.5,
  });
  const yScale = scaleLinear({
    domain: [0, 15],
    nice: true,
  });

  xScale.rangeRound([0, xMax]);
  yScale.range([yMax, 0]);
  const eachBand = xScale.step()

  return (
    <Box position="relative">
      <svg width={width} height={height}>
        <Group top={emedMargin.top} left={emedMargin.left}>
          {keys.map(key => (
            <TheLinePath
              key={key}
              data={data}
              x={d => xScale(x(d))}
              y={d => yScale(d[key])}
            />
          ))}
          <NodeGroup
            data={keys.map(key => ({ key, active: key === activeKey }))}
            keyAccessor={d => d.key}
            start={() => ({ opacity: 0 })}
            enter={(d) => ({
              opacity: [+d.active],
              timing: tweenConfig,
            })}
            update={(d) => ({
              opacity: [+d.active],
              timing: tweenConfig,
            })}
          >
            {nodes => (
              <>
                {nodes.map(node => (
                  <TheLinePath
                    key={node.key}
                    data={data}
                    x={d => xScale(x(d))}
                    y={d => yScale(d[node.key])}
                    opacity={node.state.opacity}
                    stroke={theme.colors.primary}
                  />
                ))}
              </>
            )}
          </NodeGroup>
        </Group>
        <Bar
          x={emedMargin.left}
          y={0}
          width={xMax + eachBand * 0.5}
          height={height}
          fill="transparent"
          data={data}
          onMouseMove={event => {
            if (tooltipTimeout) clearTimeout(tooltipTimeout);
            const p = localPoint(event);
            const index = Math.min(Math.round((p.x - emedMargin.left - eachBand * 0.5) / eachBand), data.length - 1);
            const top = p.y + (2 * em);
            const left = emedMargin.left + xScale(x(data[index]))
            showTooltip({
              tooltipData: { index },
              tooltipTop: top,
              tooltipLeft: left
            });
          }}
          onMouseLeave={event => {
            tooltipTimeout = setTimeout(() => {
              hideTooltip();
            }, 300);
          }}
        />
        <Group left={emedMargin.left}>
          <AxisLeft
            {...merge({}, tickLeft(emedMargin, yScale), { labelProps: { x: '-4em' } })}
            {...yTick}
          />
          <AxisBottom
            {...tickBottom(emedMargin, xScale, xMax, yMax)}
            {...xTick}
          />
        </Group>
        {tooltipData && (
          <Line
            from={{ x: tooltipLeft, y: emedMargin.top }}
            to={{ x: tooltipLeft, y: yMax + emedMargin.top }}
            stroke="white"
            strokeWidth={2}
            style={{ pointerEvents: 'none' }}
            strokeDasharray="2,2"
          />
        )}
      </svg>
      <Flex
        position="absolute"
        top="0"
        left="0"
        right="0"
        flexWrap="wrap"
        mx="-0.25em"
      >
        {keys.map(key => (
          <Button
            key={key}
            width={['7em', '6em']}
            my="0.25em"
            mx="0.25em"
            fontSize="1.125em"
            px="0.5em"
            onClick={() => updateActiveKey(key)}
            active={key === activeKey}
          >
            <NumberAndText>
              {key}
            </NumberAndText>
          </Button>
        ))}
      </Flex>
      {tooltipOpen && (
        <TooltipBox
          top={tooltipTop}
          left={tooltipLeft}
        >
          <Text color="primary">{x(data[tooltipData.index])}{typeof xAccessor === 'string' && xAccessor}</Text>
          <Text my="0.5em">
            {valueFormat(data[tooltipData.index][activeKey])}
          </Text>
        </TooltipBox>
      )}
    </Box>
  );
})
