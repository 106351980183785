import React from 'react';
import { Group } from '@vx/group';

const Coin = ({ left, top, size = 43.07 }) => (
  <Group left={left} top={top}>
    <g transform={`scale(${size / 43.07})`}>
      <circle cx="21.53" cy="21.53" r="20.78" fill="#e29c00" stroke="#16221d" strokeWidth="1.5"/>
      <circle cx="21.53" cy="21.53" r="16.75" fill="#fcc013" stroke="#fff" strokeWidth="1.5"/>
      <path d="M21.45,27a3.43,3.43,0,0,0,2.15-.52,1.58,1.58,0,0,0,.64-1.27,1.35,1.35,0,0,0-.5-1.09,4.39,4.39,0,0,0-1.49-.73L20.8,23a18,18,0,0,1-2.17-.82A6.47,6.47,0,0,1,17,21.09a4.35,4.35,0,0,1-1.06-1.45,4.8,4.8,0,0,1-.39-2,4.52,4.52,0,0,1,1.2-3.22,6.19,6.19,0,0,1,3.41-1.73V10.41a4.54,4.54,0,0,1,.71-.16,6.28,6.28,0,0,1,1-.07,2.76,2.76,0,0,1,1.62.38A1.72,1.72,0,0,1,24,12v.51a7.58,7.58,0,0,1,2.9.89A2,2,0,0,1,28,15.19a2,2,0,0,1-.33,1.15,3.62,3.62,0,0,1-.76.84,6,6,0,0,0-1.73-.77,8.42,8.42,0,0,0-2.36-.31,3.8,3.8,0,0,0-2,.42,1.27,1.27,0,0,0-.66,1.09,1,1,0,0,0,.43.86,5.5,5.5,0,0,0,1.34.62l1.53.51a16.49,16.49,0,0,1,2.35,1,7.13,7.13,0,0,1,1.69,1.19,4.39,4.39,0,0,1,1,1.54,5.55,5.55,0,0,1,.34,2,4.77,4.77,0,0,1-1.22,3.31A6.5,6.5,0,0,1,24,30.44v2.22l-.69.13a8,8,0,0,1-1,.07,2.74,2.74,0,0,1-1.63-.39A1.68,1.68,0,0,1,20.14,31v-.46a8.18,8.18,0,0,1-3.34-1,2.31,2.31,0,0,1-1.3-2A1.85,1.85,0,0,1,16,26.26a3.64,3.64,0,0,1,1-.83,7.58,7.58,0,0,0,1.92,1.11A6.69,6.69,0,0,0,21.45,27Z" fill="#fff"/>
    </g>
  </Group>
);

export default Coin;
