import React from 'react';

import Box from '../../../components/Box'
import Container from '../../../components/Container'
import Image from '../../../components/Image'
import DotsBg from '../../../components/DotsBg'

import left from './triangle-l.svg'
import right from './triangle-r.svg'

const Transition = ({ containerWidth }) => {
  return (
    <DotsBg position="relative">
      <Container mx="0" ml="0" mr="0" width={containerWidth}>
        <Image src={left} />
      </Container>
      <Box position="absolute" width="100%" top="0">
        <Container mx="0" ml="auto" mr="0" width={containerWidth}>
          <Image src={right} />
        </Container>
      </Box>
    </DotsBg>
  );
};

export default Transition;
