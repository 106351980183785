import React, { forwardRef } from 'react';

import Box from './Box';
import { containerWidth } from './ThemeProvider/theme';

const Container = forwardRef((props, ref) => {
  return <Box ref={ref} {...props} />
});

Container.defaultProps = {
  mx: 'auto',
  px: 0,
  maxWidth: containerWidth,
};

Container.displayName = 'Container';

export default Container;
