import React from 'react';

import Box from '../Box'

const TooltipBox = (props) => (
  <Box
    position="absolute"
    bg="rgba(0,0,0,0.9)"
    color="white"
    px="1.5em"
    py="0.5em"
    pointerEvents="none"
    fontSize="1.125em"
    {...props}
  />
);

export default TooltipBox;
