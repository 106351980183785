import React from 'react';

import Box from '../../../components/Box'
import Text from '../../../components/Text'
import Image from '../../../components/Image'
import Container from '../../../components/Container'
import NumberAndText from '../../../components/NumberAndText'

import bg from './bg.svg'

const Page = ({ resizeRatio, containerWidth, isMobile }) => {
  return (
    <Box height="100%" position="relative" bg="orange">
      <Container position="relative" width={containerWidth}>
        <Box position="absolute" left="0" right="0" top="0">
          <Image data-src={bg} />
        </Box>
        <Box
          position="absolute"
          style={{
            width: 528 * resizeRatio,
            left: 223 * resizeRatio,
            top: 146 * resizeRatio,
          }}
        >
          <Text.h2 color="primary">觀眾支持行為剖析<Text.inline fontSize="45.45%" color="white">（年平均）</Text.inline></Text.h2>
        </Box>
        <Box
          position="absolute"
          textAlign="center"
          lineHeight="1.25"
          color="white"
          style={{ left: 213 * resizeRatio, top: 418 * resizeRatio }}
        >
          <Text fontSize="4.5em" color="primary">53%</Text>
          <Text.h4 fontSize="1.75em">購票張數</Text.h4>
          <NumberAndText is="h4" fontSize="1.75em">超過2張</NumberAndText>
        </Box>
        <Box
          position="absolute"
          textAlign="center"
          lineHeight="1.25"
          color="white"
          style={{ left: ( isMobile ? 515 : 525 ) * resizeRatio, top: ( isMobile ? 360 : 400 ) * resizeRatio }}
        >
          <Text fontSize="4.5em" color="primary">35%</Text>
          <Text.h4 fontSize="1.75em">會員購票</Text.h4>
          <NumberAndText is="h4" fontSize="1.75em">超過1次</NumberAndText>
          <NumberAndText mt="0.25em">（1檔節目）</NumberAndText>
        </Box>
        <Box
          position="absolute"
          textAlign="center"
          lineHeight="1.25"
          color="white"
          style={{ left: ( isMobile ? 794 : 814 ) * resizeRatio, top: 405 * resizeRatio }}
        >
          <Text fontSize="4.5em" color="primary">88%</Text>
          <Text.h4 fontSize="1.75em">偏好欣賞單一<br />的演出類型</Text.h4>
          <Text mt="0.5em">（如：只買戲劇或音樂）</Text>
        </Box>
      </Container>
    </Box>
  );
};

export default Page;
