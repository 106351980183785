import React, { createElement } from 'react';
import { format } from 'd3-format'

import Box from '../../../components/Box'
import Text from '../../../components/Text'
import NumberAndText from '../../../components/NumberAndText'
import Image from '../../../components/Image'
import Container from '../../../components/Container'
import DotsBg from '../../../components/DotsBg'
import RoundTitleLabel from '../../../components/RoundTitleLabel'
import MultiLine from '../../../components/Charts/MultiLine'
import TickLabel from '../../../components/Charts/TickLabel'

import FullLeft from '../../FullLeft';
import useCSV from '../../../utils/useCSV'
import getYtickLabel from '../../getYtickLabel'

import bg from './bg.svg'
import numbers from './numbers.svg'

import csv from './p11.csv'

const Page = ({ resizeRatio, containerWidth, em, toActive }) => {
  const { data } = useCSV(csv, toActive)
  return (
    <Box height="100%" position="relative" bg="orange">
      <Container position="relative" height="100%" width={containerWidth}>
        <Image data-src={bg} />
        <Box top="3.75em" left="6.5em" position="absolute" width={['22em', '20em']} color="white">
          <Text.h2 color="primary">購票的最佳時段</Text.h2>
          <Box borderBottom="1px solid white" py="0.5em" mb="0.5em">
            <Text.h3 display="inline" fontSize="2.5em">中午</Text.h3>
            <Text.inline fontSize="1.625em">是購票的尖峰時段</Text.inline>
            <NumberAndText fontSize="1.625em">因新節目多於中午12:00</NumberAndText>
            <Text fontSize="1.625em">正式開賣</Text>
          </Box>
          <Text.h3 fontSize="2.5em">20:00後</Text.h3>
          <Text fontSize="1.625em">也是購票高峰，且年齡愈輕有愈晚購票的傾向趨勢</Text>
        </Box>
        <Box
          position="absolute"
          style={{
            top: 700 * resizeRatio,
            left: 527 * resizeRatio,
          }}
        >
          <Text color="white">註：購票張數佔比：各年齡層會員於各時間之購票張數，佔該年齡會員之總購票張數比例。</Text>
        </Box>
        <DotsBg
          position="absolute"
          style={{
            width: 828 * resizeRatio,
            top: 89 * resizeRatio,
            left: 481 * resizeRatio,
            height: 596 * resizeRatio,
          }}
        >
          <RoundTitleLabel mt="2.5em" ml="2em">2013-2019年 各年齡層購票張數佔比</RoundTitleLabel>
          <Box position="absolute" top="0" left="2.75em" right="0" bottom="0">
            <Box position="absolute" top="7.15em" left="0" right="2em" bottom="2em">
              {data && (
                <MultiLine
                  width={752 * resizeRatio}
                  height={480 * resizeRatio}
                  data={data}
                  xAccessor="時"
                  keys={Object.keys(data[0]).filter(k => k !== '時')}
                  xTick={{
                    label: '（時）',
                    tickComponent: ({ formattedValue, ...tickProps }) => createElement(TickLabel, { ...tickProps, y: '1.375em', dx: '-0.25em' }, formattedValue),
                  }}
                  yTick={{
                    label: getYtickLabel('購票張數佔比', '％'),
                    tickValues: [0, 5, 10, 15],
                  }}
                  margin={{
                    top: 7.5
                  }}
                  valueFormat={d => `${format('.1f')(d)}%`}
                  em={em}
                />
              )}
            </Box>
          </Box>
        </DotsBg>
      </Container>
      <FullLeft src={numbers} containerWidth={containerWidth} />
    </Box>
  );
};

export default Page;
