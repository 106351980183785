import React, {
  createElement,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import { MediaSensor } from 'libreact/lib/MediaSensor';
import Measure from 'react-measure'
import { createGlobalStyle } from 'styled-components';
import debounce from 'lodash/debounce'
import innerHeight from 'ios-inner-height'

import MediaContext from './mediaContext'

import { breakpoints } from '../../components/ThemeProvider/theme';
import Container from '../../components/Container';

const enhancer = breakpoints.reduce((composed, bp) => SubComp => ({ responsive, ...props }) => (
  <MediaSensor query={`(min-width: ${bp})`}>
    {({ matches }) => createElement(composed(SubComp), { ...props, responsive: [matches, ...(responsive || [])] })}
  </MediaSensor>
), SubComp => SubComp)

const FontSizeStyle = createGlobalStyle`
body {
  font-size: ${({ fontSize }) => fontSize}px;
}
`

const MediaQuery = ({ children, responsive }) => {
  const [dims, setDims] = useState({ width: 1366 });
  const [inited, setinited] = useState();
  const handler = useCallback(debounce(setDims, 200, { leading: true }), []);
  const [handleSize] = useState(() => () => {
    const w = window.innerWidth
    const h = innerHeight()
    const ratio = w > h ? h / w : w / h
    return [
      ratio > 0.5
        ? 0.86
        : (ratio < 0.42 ? 0.72 : 0.8)
      , 0.86
    ]
  })
  const [width, updateWidth] = useState(handleSize)
  useEffect(() => {
    const onUpdate = () => {
      setTimeout(() => {
        const w = handleSize()
        updateWidth(w)
      })
    }
    window.addEventListener('re:HeightUpdated', onUpdate, { once: true })
    window.addEventListener('orientationchange', onUpdate);

    return function cleanup() {
      window.removeEventListener('re:HeightUpdated', onUpdate)
      window.removeEventListener('orientationchange', onUpdate);
    };
  })
  const resizeRatio = (dims.width / 1366).toFixed(2)
  const em = (16 * resizeRatio)
  return (
    <MediaContext.Provider
      value={{
        responsive,
        resizeRatio,
        em,
        inited,
        containerWidth: width,
      }}
    >
      <>
        {useMemo(() => (
          <>
            {children}
            <FontSizeStyle fontSize={em} />
          </>
        ), [em, children])}
        <Measure
          bounds
          onResize={contentRect => {
            handler(contentRect.bounds)
            setinited(true)
          }}
        >
          {({ measureRef }) => <Container name="master" ref={measureRef} width={width} />}
        </Measure>
      </>
    </MediaContext.Provider>
  )
}

export default enhancer(MediaQuery);
