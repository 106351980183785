import React from 'react'
import SVG from './SVG'

const Line = () => {
  return (
    <SVG viewBox="0 0 40 40" width="2em">
      <g id="line">
        <path id="Path_8199" d="M19.8,8.8c-6.7,0-12.2,4.5-12.2,10,0,4.9,4.3,9.1,10.2,9.8a2,2,0,0,1,1.1.6,2.05,2.05,0,0,1,0,1.1l-.2,1c0,.3-.2,1.2,1.1.7a41.91,41.91,0,0,0,9.6-7.1A8.72,8.72,0,0,0,32,18.8c0-5.5-5.5-10-12.2-10ZM15,22.1H12.6a.58.58,0,0,1-.6-.6V16.6a.65.65,0,0,1,1.3-.1v4.3H15a.58.58,0,0,1,.6.6A.66.66,0,0,1,15,22.1Zm2.5-.7a.65.65,0,0,1-1.3.1v-5a.65.65,0,1,1,1.3-.1v5Zm5.9,0a.68.68,0,0,1-.4.6h-.2a.55.55,0,0,1-.5-.3l-2.5-3.4v3a.58.58,0,0,1-.6.6.65.65,0,0,1-.6-.6h0V16.4a.68.68,0,0,1,.4-.6h.2a.55.55,0,0,1,.5.3l2.5,3.4v-3a.65.65,0,1,1,1.3-.1v.1Zm3.9-3.1a.6.6,0,1,1,0,1.2H25.5v1.2h1.8a.58.58,0,0,1,.6.6.65.65,0,0,1-.6.6H24.9a.58.58,0,0,1-.6-.6V16.4a.58.58,0,0,1,.6-.6h2.4c.4,0,.6.3.6.7a.65.65,0,0,1-.6.6H25.5v1.2Z" fill="#fff"/>
      </g>
    </SVG>
  )
}

export default Line
