import React, { useMemo } from 'react'
import isArray from 'lodash/isArray'

import Text from './Text'

const NumberAndText = ({ children, is, inlineIs, respectText, noResize, ...props }) => {
  const getParsed = useMemo(() => {
    let parsed = []
    const extract = source => {
      const extracted = []
      const re = new RegExp(`[${respectText ? '' : '^'}\\d|\\-|,|.|%]+`, 'g')
      let m
      let lastIndex = 0
      while ((m = re.exec(source))) {
        if (m.index > lastIndex) {
          extracted.push(source.substring(lastIndex, m.index))
        }
        extracted.push(
          <Text.inline
            key={`t-${m.index}`}
            is={inlineIs || 'span'}
            mx={respectText && '0.1em'}
            fontSize={respectText ? '1.2em' : '0.875em'}
            lineheight={respectText && '1'}
            noResize={noResize}
          >{m[0]}</Text.inline>
        )
        lastIndex = Number(re.lastIndex)
      }
      if (lastIndex > 0 && lastIndex < source.length) {
        extracted.push(source.substring(lastIndex));
      }
      parsed = parsed.concat(extracted.length ? extracted : source)
    }
    if (typeof children === 'string') {
      extract(children)
    } else if (isArray(children)) {
      children.forEach(c => {
        if (typeof c === 'string') extract(c)
      })
    }
    return parsed
  }, [JSON.stringify(children)])

  return (
    <Text is={is} noResize={noResize} {...props}>
      {getParsed}
    </Text>
  )
}

NumberAndText.defaultProps = {
  respectText: true,
}

export default NumberAndText
