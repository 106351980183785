import React, { useMemo, useCallback, useRef } from 'react';
import { BarStack } from '@vx/shape';
import { Group } from '@vx/group';
import { AxisBottom, AxisLeft } from '@vx/axis';
import { scaleBand, scaleLinear, scaleOrdinal } from '@vx/scale';
import { withTooltip } from '@vx/tooltip';
import { localPoint } from '@vx/event';
import mapValues from 'lodash/mapValues'
import get from 'lodash/get'
import throttle from 'lodash/throttle'
import range from 'lodash/range'
import merge from 'lodash/merge'
import { useImmer } from 'use-immer';
import { NodeGroup } from 'react-move'
import { format } from 'd3-format'
import { select } from 'd3-selection'
import 'd3-transition'

import Box from '../Box'
import Text from '../Text'
import TooltipBox from './TooltipBox'
import LegendOptions from './LegendOptions';

import { tickLeft, tickBottom } from './axisConfig'
import tweenConfig from './tweenConfig'

import theme from '../ThemeProvider/theme'

const p = format('.0%')

let tooltipTimeout;

const defaultMargin = {
  top: 3,
  left: 4.5,
  right: 5,
  bottom: 2,
}

const AnimateBars = React.memo(({ left, top, height, width, fill, hideTooltip, throttleHandleMouseMove, bar }) => (
  <Group left={left} top={top}>
    <rect
      stroke="black"
      strokeWidth="0.1em"
      height={height}
      width={width}
      fill={fill}
      onMouseLeave={() => {
        tooltipTimeout = setTimeout(() => {
          hideTooltip();
        }, 300);
      }}
      onMouseMove={e => {
        if (tooltipTimeout) clearTimeout(tooltipTimeout);
        throttleHandleMouseMove(localPoint(e), bar)
      }}
    />
  </Group>
))

export default withTooltip(
  ({
    data,
    xAccessor,
    keys,
    colors,
    width,
    height,
    margin,
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
    xTick = {},
    yTick = {},
    yMax: yMaxOveride,
    valueFormat = v => v,
    showSum,
    showSumButNotAll = true,
    hideLegned,
    showPercentOnTooltip,
    noTooltip,
    activated,
    sumOverride = [],
    isActive,
    em,
    isMobile,
  }) => {
    if (!data || !xAccessor || !keys || !colors) return null
    const x = typeof xAccessor === 'string' ? d => d[xAccessor] : xAccessor
    if (isNaN(em)) return null
    const refs = range(data.length).map(() => useRef())
    const [activeKeys, updateActiveKeys] = useImmer(() => keys.reduce((ak, k) => {
      ak[k] = true
      return ak
    }, {}))
    const keysCount = useMemo(() => Object.values(activeKeys).reduce((c, b) => c + b, 0), [activeKeys])
    const onlyOneKey = keysCount === 1
    const allKeys = keysCount === keys.length

    const totals = useMemo(() => data.reduce((ret, cur) => ret.concat(keys.reduce((t, k) => t + +cur[k], 0)), []), [data]);

     // scales
     const xScale = useMemo(() => scaleBand({
      domain: data.map(x),
      padding: 0.5,
    }), [JSON.stringify(data)])
    const yMaxValue = yMaxOveride || Math.max(...totals)
    const yScale = useMemo(() => scaleLinear({
      domain: [0, yMaxValue],
      nice: true,
    }), [JSON.stringify(totals), yMaxOveride]);
    const emedMargin = useMemo(() => mapValues(Object.assign({}, defaultMargin, margin), d => d * em), [em, margin.left])
    // bounds
    const xMax = width - emedMargin.left - emedMargin.right;
    const yMax = height - emedMargin.top - emedMargin.bottom;

    const color = useMemo(() => scaleOrdinal({
      domain: keys,
      range: colors,
    }), [colors, keys])

    xScale.rangeRound([0, xMax]);
    yScale.range([yMax, 0]);
    const keysReverse = Array.from(keys).reverse()
    const theKeys = keysReverse.filter(k => activeKeys[k])

    const handleMouseMove = useCallback((point, bar) => {
      const top = point.y - emedMargin.top;
      const offset = xScale.paddingInner() * xScale.step() / 2;
      const left = bar.x + bar.width + offset;
      showTooltip({
        tooltipData: bar,
        tooltipTop: top,
        tooltipLeft: left,
      });
    }, [xScale, emedMargin, showTooltip])

    const noAnimate = isActive === false

    const throttleHandleMouseMove = useCallback(throttle(handleMouseMove, 250), [em]);
    return (
      <Box position="relative">
        {useMemo(() => (
          <>
            <svg width={width} height={height}>
              <Group top={emedMargin.top} left={emedMargin.left}>
                <BarStack
                  data={data}
                  keys={theKeys}
                  x={x}
                  xScale={xScale}
                  yScale={yScale}
                  color={color}
                >
                  {barStacks => {
                    const keyed = barStacks.reduce((kd, bs) => {
                      kd[bs.key] = bs
                      return kd
                    }, {})
                    return keysReverse.map(key => {
                      const barStack = keyed[key]
                      activated && barStack && barStack.bars.forEach(bar => {
                        if (get(barStack, 'index') === keysCount - 1 && refs[bar.index] && refs[bar.index].current) {
                          let selection = select(refs[bar.index].current)
                          if (!noAnimate) {
                            selection = selection
                              .transition()
                              .duration(tweenConfig.duration)
                              .ease(tweenConfig.ease)
                          }
                          selection
                            .attr('x', xScale.paddingInner() * bar.width + bar.x)
                            .attr('y', bar.y - 0.5 * em)
                            .text(valueFormat((showSum || showSumButNotAll)
                              ? (allKeys && sumOverride[bar.index] ? sumOverride[bar.index] : theKeys.reduce((s, k) => s + +bar.bar.data[k], 0))
                              : bar.bar.data[bar.key])
                            )
                        }
                      })
                      return activated && (
                        <NodeGroup
                          key={key}
                          data={barStack ? barStack.bars : []}
                          keyAccessor={b => b.index}
                          start={(d) => ({
                            height: 0,
                            y: d.height + d.y,
                          })}
                          enter={(d) => ({
                            height: [d.height],
                            y: [d.y],
                            timing: tweenConfig,
                          })}
                          update={(d) => ({
                            height: [d.height],
                            y: [d.y],
                            timing: tweenConfig,
                          })}
                          leave={(d) => ({
                            height: [0],
                            y: [d.y + d.height],
                            timing: tweenConfig,
                          })}
                        >
                          {nodes => {
                            return (
                              <>
                                {nodes.map(({ data: bar, state }) => {
                                  return (
                                    <AnimateBars
                                      key={`bar-stack-${key}-${bar.index}`}
                                      left={bar.x}
                                      top={noAnimate ? bar.y : state.y}
                                      height={noAnimate ? bar.height : state.height}
                                      width={bar.width}
                                      fill={bar.color}
                                      hideTooltip={hideTooltip}
                                      throttleHandleMouseMove={throttleHandleMouseMove}
                                      bar={bar}
                                    />
                                  );
                                })}
                              </>
                            )
                          }}
                        </NodeGroup>
                      )
                    }).concat(data.map((d, i) => (
                      <text
                        key={`label-${i}`}
                        ref={refs[i]}
                        x={xScale(x(d)) + xScale.bandwidth() / 2}
                        y={yMax}
                        textAnchor="middle"
                        fontSize="1.5em"
                        fill={theme.colors.primary}
                        opacity={+(onlyOneKey || showSum || (!allKeys && showSumButNotAll))}
                        style={{ transition: 'opacity 500ms ease' }}
                      />
                    )))
                  }}
                </BarStack>
              </Group>
              <Group left={emedMargin.left}>
                <AxisLeft
                  {...merge(tickLeft(emedMargin, yScale, isMobile), yTick)}
                />
                <AxisBottom
                  {...merge(tickBottom(emedMargin, xScale, xMax, yMax), xTick)}
                />
              </Group>
            </svg>
            {!hideLegned && (
              <Box
                position="absolute"
                top={0}
                right={0}
              >
                <LegendOptions
                  scale={color}
                  activeKeys={activeKeys}
                  updateActiveKeys={updateActiveKeys}
                  onlyOneKey={onlyOneKey}
                />
              </Box>
            )}
          </>
        ), [
          JSON.stringify(activeKeys),
          em,
          JSON.stringify(data),
          activated,
          emedMargin.left,
        ])}
        {tooltipOpen && !noTooltip && (
          <TooltipBox
            top={tooltipTop}
            left={tooltipLeft}
          >
            <Text fontWeight="bold" color={color(tooltipData.key)}>{tooltipData.key}</Text>
            <Text>
              {valueFormat(tooltipData.bar.data[tooltipData.key])}
              {showPercentOnTooltip && ` (${p(tooltipData.bar.data[tooltipData.key] / tooltipData.bar.data.sum)})`}
            </Text>
          </TooltipBox>
        )}
      </Box>
    );
  }
);
