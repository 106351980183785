import { createGlobalStyle } from 'styled-components';

import heavyTtf from './minified/GenJyuuGothic-Heavy.ttf';
import balooBhai2TTF from './minified/BalooBhai2-Bold.ttf';
import balooBhai2WOFF from './minified/BalooBhai2-Bold.woff';

export default createGlobalStyle`
  @font-face {
    font-family: 'Gen Jyuu Gothic';
    src: local("Gen Jyuu Gothic Heavy"),
      url(${heavyTtf});
    font-weight: 800;
  }
  @font-face {
    font-family: 'Baloo Bhai 2';
    src: local("Baloo Bhai 2 Bold"),
      url(${balooBhai2WOFF}),
      url(${balooBhai2TTF});
    font-weight: 700;
  }
`;
