import React from 'react';

import Box from '../../../components/Box'
import Container from '../../../components/Container'
import Image from '../../../components/Image'

import right from './right.svg'

const Transition = ({ resizeRatio, containerWidth }) => {
  return (
    <Box
      style={{
        marginTop: -150 * resizeRatio,
        marginBottom: -234 * resizeRatio,
      }}
      bg="orange"
      pointerEvents="none"
    >
      <Container
        mx="0"
        ml="auto"
        mr="0"
        position="relative"
        zIndex={1}
        width={containerWidth}
      >
        <Image src={right} />
      </Container>
    </Box>
  );
};

export default Transition;
