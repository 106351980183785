import React from 'react';

import Box from '../../../components/Box'
import Container from '../../../components/Container'
import Image from '../../../components/Image'

import left from './left.svg'
import right from './right.svg'

const Transition = ({ resizeRatio, containerWidth }) => {
  return (
    <Box
      bg="orange"
      pointerEvents="none"
      style={{
        marginBottom: -67 * resizeRatio,
        height: 90 *resizeRatio,
      }}
    >
      <Box position="absolute" width={1}>
        <Container
          mx="0"
          ml="0"
          mr="auto"
          position="relative"
          zIndex={1}
          width={containerWidth}
        >
          <Image src={left} />
        </Container>
      </Box>
      <Box position="absolute" width={1}>
        <Container
          mx="0"
          ml="auto"
          mr="0"
          position="relative"
          zIndex={1}
          width={containerWidth}
        >
          <Image src={right} />
        </Container>
      </Box>
      {/* <Box
        position="absolute"
        borderBottom="0.125em solid black"
        style={{
          left: 120 * resizeRatio,
          right: 120 * resizeRatio,
        }}
      /> */}
    </Box>
  );
};

export default Transition;
