import React from 'react';
import { format } from 'd3-format'

import frame from './frame.svg'
import light from './light.svg'
import csv from './p4.csv'

import NumberAndText from '../../../components/NumberAndText'
import Box from '../../../components/Box'
import Container from '../../../components/Container'
import Text from '../../../components/Text'
import Image from '../../../components/Image'
import DotsBg from '../../../components/DotsBg'
import RoundTitleLabel from '../../../components/RoundTitleLabel'
import StackedBars from '../../../components/Charts/StackedBars'
import getYtickLabel from '../../getYtickLabel'

import useCSV from '../../../utils/useCSV'

const Page = ({ resizeRatio, activated, containerWidth, em, isMobile, toActive }) => {
  const { data } = useCSV(csv, toActive)
  return (
    <Box height="100%" position="relative" bg="orange">
      <Container pt={['1.25em', '2em']} position="relative" height="100%" width={containerWidth}>
        <Box textAlign="center">
          <Text.h2 fontWeight="bold" color="primary" fontSize="2.75em">
            支持表演藝術的觀眾大幅成長
          </Text.h2>
          <NumberAndText is="h4" color="white" fontSize="1.5em">
            2019年兩廳院售票系統購票會員人數較2018年成長28%
          </NumberAndText>
          <Box
            position="absolute"
            style={{
              width: 77 * resizeRatio,
              left: 138 * resizeRatio,
              top: 55 * resizeRatio
            }}
          >
            <Image data-src={light} />
          </Box>
        </Box>
        <DotsBg position="absolute" style={{ top: 140 * resizeRatio }} left="0" right="0">
          <Image data-src={frame} />
          <Box position="absolute" top="0" left="10.5em" right="10.5em" bottom="5em">
            <RoundTitleLabel mt="3.75em">2013-2019年 購票會員人數</RoundTitleLabel>
            <Box position="absolute" top="10em" left="0" right="0" bottom="3.375em">
              {data && (
                <StackedBars
                  activated={activated}
                  width={1040 * resizeRatio}
                  height={360 * resizeRatio}
                  data={data}
                  xAccessor="年"
                  keys={['購票人數']}
                  xTick={{ label: '（年）' }}
                  yTick={{
                    label: getYtickLabel('人數', '萬'),
                    tickValues: [0, 5, 10, 15, 20],
                  }}
                  valueFormat={format('.1f')}
                  margin={{
                    top: 1.5,
                  }}
                  colors={[ '#9faac4' ]}
                  hideLegned
                  noTooltip
                  em={em}
                  isMobile={isMobile}
                />
              )}
            </Box>
          </Box>
        </DotsBg>
        <Box position="absolute" style={{ top: 720 * resizeRatio, left: 276 * resizeRatio }} color="white">
          <NumberAndText>註：會員其在該年度曾購票1張以上，即視為在該年度曾購票。</NumberAndText>
        </Box>
      </Container>
    </Box>
  );
};

export default Page;
