import React, { useRef, useMemo, forwardRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { TiArrowSortedUp } from 'react-icons/ti'

import Box from '../Box'
import Text from '../Text'
import Button from '../Button'

const defaultConfig = {
  dots: false,
  arrows: false,
  speed: 500,
}

const C = p => (
  <Button.circle
    bg="primary"
    border="0.125em solid black"
    px="0.375em"
    py="0.375em"
    mb="0.5em"
    {...p}
  />
)

const Carousel = forwardRef(({
  config,
  children,
  arrowsOffset = '7em',
  prevLabel = 'Prev',
  nextLabel = 'Next',
}, ref) => {
  const slickRef = ref || useRef()
  return (
    <>
      <Box
        position="absolute"
        top="50%"
        left={`-${arrowsOffset}`}
        transform="translate(50%, -50%)"
        textAlign="center"
        width="2.875em"
        onClick={() => slickRef.current.slickPrev()}
      >
        <C transform="rotate(-90deg)">
          <TiArrowSortedUp size="1.5em" />
        </C>
        <Text color="white" fontSize="1.25em">
          {prevLabel}
        </Text>
      </Box>
      {useMemo(() => (
        <Slider ref={slickRef} {...Object.assign({}, defaultConfig, config)}>
          {children}
        </Slider>
      ), [children, config])}
      <Box
        position="absolute"
        top="50%"
        right={`-${arrowsOffset}`}
        transform="translate(-50%, -50%)"
        textAlign="center"
        width="2.875em"
        onClick={() => slickRef.current.slickNext()}
      >
        <C transform="rotate(90deg)">
          <TiArrowSortedUp size="1.5em" />
        </C>
        <Text color="white" fontSize="1.25em">
          {nextLabel}
        </Text>
      </Box>
    </>
  );

})
export default Carousel
