import React from 'react'
import { LegendOrdinal } from '@vx/legend';
import mapValues from 'lodash/mapValues'

import Box from '../Box'
import Flex from '../Flex'
import NumberAndText from '../NumberAndText'
import Checkbox from '../Checkbox'

const LegendOptions = ({
  scale,
  onlyOneKey,
  activeKeys,
  updateActiveKeys,
}) => {
  return (
    <LegendOrdinal scale={scale}>
      {labels => (
        <>
          <Box.inline color="white" borderBottom="2px solid white">可依類別點選</Box.inline>
          {labels.map(l => (
            <Flex
              key={l.datum}
              is="label"
              my="0.5em"
              cursor="pointer"
              minWidth={`${l.text.length + 3.75}em`}
            >
              <Checkbox
                fontSize="1.5em"
                color="white"
                name="active"
                checked={activeKeys[l.datum]}
                onChange={(e) => updateActiveKeys(draft => {
                  if (onlyOneKey && !e.target.checked) {
                    draft = mapValues(draft, () => true)
                  } else {
                    draft[l.datum] = e.target.checked;
                  }
                  return draft
                })}
              />
              <Box
                ml="0.5em"
                flex="1"
                bg={l.value}
                color="black"
                textAlign="center"
                borderRadius="2em"
                px="0.75em"
                pt="0.25em"
                userSelect="none"
              >
                <NumberAndText>
                  {l.text}
                </NumberAndText>
              </Box>
            </Flex>
          ))}
        </>
      )}
    </LegendOrdinal>
  )
}

export default LegendOptions
