import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import innerHeight from 'ios-inner-height'
import { enableES5 } from 'immer'

import phone from './phone.svg'

import HomePage from './containers/HomePage'
import DotsBg from './components/DotsBg'
import Box from './components/Box'
import Image from './components/Image'
import Text from './components/Text'
import Loading from './components/Loading'
import browser from './components/utils/browser'
import isIE from './components/utils/isIE'

import StepPreloader from './containers/StepPreloader'
import preload from './preload'

if (isIE) enableES5()

const rotate = keyframes`
0%, 10% {
  transform: rotate(-90deg);
}
50%, 100% {
  transform: rotate(0deg);
}
`

const Rotation = styled.div`
  animation: ${rotate} 3s infinite;
`

const Alert = () => (
  <DotsBg color="yellow" textAlign="center" fontSize="5em" height="100vh">
    <Box position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">
      <Box px="25%">
        <Rotation>
          <Image src={phone} />
        </Rotation>
      </Box>
      <Box pt="3em">
        <Text>請使用橫式觀看</Text>
        <Text>以獲得最佳體驗</Text>
      </Box>
    </Box>
    <StepPreloader list={preload} />
  </DotsBg>
)

const isMobile = browser.getPlatformType() !== 'desktop'
let prevHeight
let handlePageInited

function App() {
  const [isHorizontal, setHorizontal] = useState(false)
  const [showPages, setShowPages] = useState()
  const [listenerInited, setInited] = useState()
  const [hasInited, setHasInited] = useState()
  const [handleOrientation] = useState(() => () => {
    const horizontal = window.orientation !== 0 && window.orientation !== 180
    setHorizontal(horizontal)
    if (horizontal) {
      setTimeout(() => {
        setShowPages(true)
        if (!listenerInited) {
          prevHeight = innerHeight()
          window.addEventListener('resize', () => {
            const newHeight = innerHeight()
            if (Math.abs(prevHeight - newHeight) > 100 ? (prevHeight > newHeight) : (newHeight > prevHeight)) {
              document.querySelector('html').style.height = `${newHeight}px`
              document.querySelectorAll('section.section').forEach((sec) => {
                sec.style.height = `${newHeight}px`
              })
              window.dispatchEvent(new Event('re:HeightUpdated'));
            }
            prevHeight = newHeight
          });
          setInited(true)
        }
      }, listenerInited ? 500 : 1000)
    } else {
      setShowPages(false)
    }
  })
  useEffect(() => {
    if (isMobile) {
      handlePageInited = handlePageInited || (() => {
        setHasInited(true)
      })
      window.addEventListener('orientationchange', handleOrientation);
      window.addEventListener('re:FisrtPageInited', handlePageInited, { once: true })
      handleOrientation()
      return function cleanup() {
        window.removeEventListener('orientationchange', handleOrientation);
        window.removeEventListener('re:FisrtPageInited', handlePageInited);
      }
    }
  }, [])
  if (isMobile) {
    return isHorizontal ? (showPages ? <HomePage hasInited={hasInited} /> : (
      <DotsBg height="100vh">
        <Box position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">
          {!listenerInited && <Loading />}
        </Box>
      </DotsBg>
    )) : <Alert />
  }
  return <HomePage />;
}

export default App;
