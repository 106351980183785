import React, { useCallback } from 'react';
import { LinePath, Line, Bar } from '@vx/shape';
import { Group } from '@vx/group';
import { AxisBottom, AxisLeft } from '@vx/axis';
import { scaleBand, scaleLinear, scaleOrdinal } from '@vx/scale';
import { withTooltip } from '@vx/tooltip';
import { localPoint } from '@vx/event';
import mapValues from 'lodash/mapValues'
import throttle from 'lodash/throttle'
import { useImmer } from 'use-immer';

import Box from '../Box'
import Text from '../Text'
import TooltipBox from './TooltipBox'
import LegendOptions from './LegendOptions'
import { tickLeft, tickBottom } from './axisConfig'

let tooltipTimeout;

const defaultMargin = {
  top: 3,
  left: 5,
  right: 5,
  bottom: 3,
}

export default withTooltip(
  ({
    activated,
    data,
    xAccessor,
    keys,
    colors,
    width,
    height,
    margin,
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
    xTick = {},
    yTick = {},
    em,
  }) => {
    if (!data || !xAccessor || !keys || !colors) return null
    if (isNaN(em)) return null
    const [activeKeys, updateActiveKeys] = useImmer(() => keys.reduce((ak, k) => {
      ak[k] = true
      return ak
    }, {}))
    const onlyOneKey = Object.values(activeKeys).reduce((c, b) => c + b, 0) === 1
    const emedMargin = mapValues(Object.assign({}, defaultMargin, margin), d => d * em)
    // bounds
    const xMax = width - emedMargin.left - emedMargin.right;
    const yMax = height - emedMargin.top - emedMargin.bottom;

    const valueMax = data.reduce((ret, cur) => Math.max(ret,
      keys.reduce((t, k) => Math.max(t, +cur[k]), 0)
    ), 0);

    // scales
    const xScale = scaleBand({
      domain: data.map(xAccessor),
      padding: 0.5,
    });
    const yScale = scaleLinear({
      domain: [0, valueMax],
      nice: true,
    });

    // const color = k => colors[k]
    const color = scaleOrdinal({
      domain: keys,
      range: colors,
    })

    xScale.rangeRound([0, xMax]);
    yScale.range([yMax, 0]);
    const eachBand = xScale.step()

    const handleMouseMove = (point) => {
      const index = Math.min(Math.round((point.x - emedMargin.left - eachBand * 0.5) / eachBand), data.length - 1);
      const top = point.y + (2 * em);
      const left = xScale(xAccessor(data[index])) + emedMargin.left;
      showTooltip({
        tooltipData: { index },
        tooltipTop: top,
        tooltipLeft: left
      });
    }

    const throttleHandleMouseMove = useCallback(throttle(handleMouseMove, 250), [em]);
    return (
      <Box position="relative">
        <svg width={width} height={height}>
          <Group top={emedMargin.top} left={emedMargin.left}>
            {keys.map(key => (
              <LinePath
                key={key}
                data={data}
                x={d => xScale(xAccessor(d))}
                y={d => yScale(d[key])}
                stroke={color(key)}
                strokeWidth={6 / 16 * em}
                strokeLinecap="round"
                opacity={activated ? +activeKeys[key] : 0}
                style={{ transition: 'opacity 750ms' }}
              />
            ))}
          </Group>
          <Bar
            x={emedMargin.left}
            y={0}
            width={xMax + eachBand * 0.5}
            height={height}
            fill="transparent"
            data={data}
            onMouseMove={event => {
              if (tooltipTimeout) clearTimeout(tooltipTimeout);
              throttleHandleMouseMove(localPoint(event));
            }}
            onMouseLeave={event => {
              tooltipTimeout = setTimeout(() => {
                hideTooltip();
              }, 300);
            }}
          />
          <Group left={emedMargin.left}>
            <AxisLeft
              {...tickLeft(emedMargin, yScale)}
              {...yTick}
            />
            <AxisBottom
              {...tickBottom(emedMargin, xScale, xMax, yMax)}
              {...xTick}
            />
          </Group>
          {tooltipData && (
            <Line
              from={{ x: tooltipLeft, y: emedMargin.top }}
              to={{ x: tooltipLeft, y: yMax + emedMargin.top }}
              stroke="white"
              strokeWidth={2}
              style={{ pointerEvents: 'none' }}
              strokeDasharray="2,2"
            />
          )}
        </svg>
        <Box
          position="absolute"
          top={0}
          right={0}
        >
          <LegendOptions
            scale={color}
            activeKeys={activeKeys}
            updateActiveKeys={updateActiveKeys}
            onlyOneKey={onlyOneKey}
          />
        </Box>
        {tooltipOpen && (
          <TooltipBox
            top={tooltipTop}
            left={tooltipLeft}
          >
            <Text>{xAccessor(data[tooltipData.index])}</Text>
            {keys.map(k => activeKeys[k] && (
              <Text my="0.5em" color={color(k)} key={`tooltip-${k}`}>
                {k}: {data[tooltipData.index][k]}
              </Text>
            ))}
          </TooltipBox>
        )}
      </Box>
    );
  }
);
