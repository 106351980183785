import React, { useState } from 'react'
import { slide as Menu } from 'react-burger-menu'

import Box from '../components/Box'
import Link from '../components/Link'
// import theme from '../components/ThemeProvider/theme'
import useResponsive from '../contexts/mediaQuery/useResponsive'


const menuItem = [
  { name: '表演大數據', to: 2 },
  { name: '購票行為解密', to: 7 },
  { name: '年度熱門排行', to: 13 },
  { name: '當表演走向各地', to: 14 },
  { name: '下載完整報告', to: 18 },
  { name: '返回首頁', to: 1 },
]

const BgMenu = ({ hide = true }) => {
  const [isOpen, setIsOpen] = useState()
  const { isMobile } = useResponsive()
  return (
    <Menu
      isOpen={isOpen}
      onStateChange={(state) => setIsOpen(state.isOpen)}
      styles={{
        bmBurgerButton: {
          position: 'fixed',
          width: isMobile ? '2.75em' : '1.75em',
          height: isMobile ? '2.5em' : '1.5em',
          left: isMobile ? '2.25em' : '1.5em',
          top: isMobile ? '3em' : '1.75em',
          opacity: +(!hide),
        },
        bmBurgerBars: {
          background: '#fff'
        },
        bmCrossButton: {
          height: '2em',
          width: '2em'
        },
        bmCross: {
          background: '#bdc3c7',
          width: '0.33em',
          height: '1.75em',
        },
        bmMenuWrap: {
          width: '20em',
          position: 'fixed',
          top: 0,
          height: '100%'
        },
        bmMenu: {
          background: '#424e63',
          padding: '2.5em 1.5em 0',
          fontSize: isMobile ? '1.25em' : '1em'
        },
        bmItemList: {
          color: '#fff',
          padding: '0.8em',
          outline: 'none',
        },
        bmOverlay: {
          top: 0,
          background: 'rgba(0, 0, 0, 0.5)'
        }
      }}
    >
      {menuItem.map((i, k) => (
        <Box
          key={k}
          width={1}
          fontSize="1.5em"
          my="0.5em"
          fontWeight="bold"
        >
          <Link
            href={`#page${i.to}`}
            target="_self"
            onClick={() => {
              setIsOpen(false)
            }}
            color="rgba(255,255,255,0.8)"
            hoverColor="white"
          >
          {i.name}
          </Link>
        </Box>
      ))}
    </Menu>
  )
}

export default BgMenu
