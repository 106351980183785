import React from 'react';
import { format } from 'd3-format'

import Box from '../../../components/Box'
import Text from '../../../components/Text'
import Image from '../../../components/Image'
import DotsBg from '../../../components/DotsBg'
import Container from '../../../components/Container'
import RoundTitleLabel from '../../../components/RoundTitleLabel'
import StackedBars from '../../../components/Charts/StackedBars'
import getYtickLabel from '../../getYtickLabel'

import { schemas } from '../../../components/ThemeProvider/theme'

import csv from './p8.csv'
import useCSV from '../../../utils/useCSV'

import left from './left.svg'
import bg from './bg.svg'

const RoundedEmph = ({ children , ...props }) => (
  <Box.inline borderRadius="1.75em" px="1em" my="0.125em" {...props}>
    <Text.h3
      fontSize="1.75em"
      fontWeight="bold"
      display="inline"
      noResize
    >
      {children}
    </Text.h3>
  </Box.inline>
)

const Page = ({ resizeRatio, activated, containerWidth, em, isMobile, toActive }) => {
  const { data } = useCSV(csv, toActive)
  return (
    <Box height="100%" bg="orange" position="relative">
      <Container position="relative" width={containerWidth}>
        <Box position="absolute" top="0" style={{ left: -22 * resizeRatio, right: -22 * resizeRatio }}>
          <Image data-src={bg} />
        </Box>
        <Box
          position="absolute"
          style={{
            top: 60 * resizeRatio,
            left: 194 * resizeRatio,
          }}
        >
          <Text.h2 color="primary">找到想看的節目類型</Text.h2>
        </Box>
        <DotsBg
          position="absolute"
          pointerEvents="all"
          style={{
            left: 360 * resizeRatio,
            top: 240 * resizeRatio,
            width: 925 * resizeRatio,
            height: 440 * resizeRatio,
          }}
        >
          <RoundTitleLabel mt="2.375em" ml="3em">2013-2019年 各年齡層購票張數佔比</RoundTitleLabel>
          <Box position="absolute" top="6.75em" left="2.375em" right="1em" bottom="1.5em">
            {data && (
              <StackedBars
                activated={activated}
                width={870 * resizeRatio}
                height={308 * resizeRatio}
                data={data}
                xAccessor={d => d['歲']}
                keys={Object.keys(schemas.show)}
                xTick={{ label: '（歲）' }}
                yTick={{
                  label: getYtickLabel('購票張數佔比', '％'),
                  tickFormat: d => String(d * 100),
                  tickValues: [0, 0.2, 0.4, 0.6, 0.8, 1],
                }}
                valueFormat={format('.0%')}
                tooltipFormat={format('.0%')}
                margin={{
                  top: 1,
                  bottom: 2.25,
                  left: 5,
                }}
                colors={Object.values(schemas.show)}
                em={em}
                isMobile={isMobile}
              />
            )}
          </Box>
        </DotsBg>
        <Box position="absolute" left="0" right="0" pointerEvents="none">
          <Image data-src={left} />
          <Box
            position="absolute"
            pointerEvents="all"
            style={{
              top: 254 * resizeRatio,
              left: 148 * resizeRatio,
              width: 250 * resizeRatio,
            }}
          >
            <Box>
              <Text noResize fontSize="2.875em" lineHeight="1">20-34<Text.inline noResize fontSize="60.87%">歲</Text.inline></Text>
              <Text noResize fontSize="1.25em">偏好欣賞</Text>
              <RoundedEmph bg="rgba(255, 211, 119, 0.5)">戲劇節目</RoundedEmph>
            </Box>
            <Box mt="0.625em">
              <Text noResize fontSize="2.875em" lineHeight="1">35-44<Text.inline noResize fontSize="60.87%">歲</Text.inline></Text>
              <RoundedEmph bg="rgba(77, 165, 142, 0.5)">親子節目</RoundedEmph>
              <Text noResize fontSize="1.25em">的主要客群</Text>
            </Box>
            <Box mt="1em">
              <Text noResize fontSize="2.875em" lineHeight="1">40<Text.inline noResize fontSize="60.87%">歲以後</Text.inline></Text>
              <Text>
                <Text.inline noResize fontSize="1.25em" mr="0.25em" verticalAlign="sub">欣賞</Text.inline>
                <RoundedEmph bg="rgba(159, 170, 196, 0.5)">音樂演出</RoundedEmph>
              </Text>
              <Text noResize fontSize="1.25em">的比例上升</Text>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Page;
