import React, { useState, useMemo } from 'react';
import groupBy from 'lodash/groupBy'
import mapValues from 'lodash/mapValues'
import get from 'lodash/get'
import isFunction from 'lodash/isFunction'
import { format } from 'd3-format'
import { useImmer } from 'use-immer'

import Button from '../../../components/Button'
import Box from '../../../components/Box'
import Flex from '../../../components/Flex'
import Text from '../../../components/Text'
import Image from '../../../components/Image'
import Container from '../../../components/Container'
import DotsBg from '../../../components/DotsBg'
import RoundTitleLabel from '../../../components/RoundTitleLabel'
import Carousel from '../../../components/Carousel'
import StackedBars from '../../../components/Charts/StackedBars'
import { schemas } from '../../../components/ThemeProvider/theme'

import useCSV from '../../../utils/useCSV'
import getYtickLabel from '../../getYtickLabel'

import regions from '../../regions'
import years from '../../years'
import bg from './bg.svg'

import csvb from './p16-boxoffice.csv'
import csve from './p16-events.csv'
import csvt from './p16-tickets.csv'

const handleData = data => mapValues(groupBy(data, '地區'), d => years.map(year => d.reduce((yd, dd) => {
  yd[dd['類別']] = dd[year]
  yd.sum += +dd[year]
  return yd
}, { 年: year, sum: 0 })))

const Page = ({ resizeRatio, activated, containerWidth, em, isMobile, toActive }) => {
  const { data: boxOfficeData } = useCSV(csvb, toActive)
  const { data: eventData } = useCSV(csve, toActive)
  const { data: ticketData } = useCSV(csvt, toActive)
  const data = useMemo(() => [
    {
      name: '演出票房',
      title: '各地區票房',
      axisLabel: getYtickLabel('票房金額', '億'),
      data: handleData(boxOfficeData),
      valueFormat: {
        '雙北': format('.1f'),
        '北部': format('.2f'),
        '中部': format('.1f'),
        '南部': format('.1f'),
        '東部': format('.2f'),
      },
      sumOverride: {
        '東部': [null, 0.1, null, 0.07, 0.06, 0.07, 0.05],
      },
      marginLeft: {
        '北部': 6,
        '東部': 6,
      },
      // tickFormat: {
      //   '北部': format('.1f'),
      //   '南部': format('.0f'),
      // }
    },
    {
      name: '售票張數',
      title: '各地區售票數',
      axisLabel: getYtickLabel('售出張數', '萬'),
      data: handleData(ticketData),
      valueFormat: format('.1f'),
      tickFormat: {
        '東部': format('.0f'),
      }
    },
    {
      name: '演出場次',
      title: '各地區演出場次',
      axisLabel: getYtickLabel('場次', '場'),
      data: handleData(eventData),
      marginLeft: {
        '北部': 6,
        '中部': 6,
        '雙北': 7,
        '南部': 7,
      },
    },
  ], [boxOfficeData, ticketData, eventData])
  const [activeIndex, setActiveIndex] = useState(0)
  const prevIndex = activeIndex === 0 ? data.length - 1 : activeIndex - 1
  const nextIndex = activeIndex === data.length - 1 ? 0 : activeIndex + 1
  const [activeRegion, setActiveRegion] = useState(0)
  const [isActivated, setActivated] = useImmer(() => [true])

  return (
    <DotsBg height="100%">
      <Container pt="2.5em" px="10em" position="relative" height="100%" width={containerWidth}>
        <Box position="absolute" left="0" top="0" right="0" pointerEvents="none">
          <Image data-src={bg} />
        </Box>
        <Text.h2 textAlign="center" color="primary">進一步觀察各地區的歷年數據</Text.h2>
        <Text.h4 mt="0.25em" mb="0.5em" textAlign="center" color="white">點擊地區看演出票房、場次與售票張數</Text.h4>
        <Flex
          position="absolute"
          alignItems="center"
          style={{
            top: 208 * resizeRatio,
            left: 136 * resizeRatio,
          }}
        >
          <Box mr="1.5em">
            <Text fontSize="1.625em" color="white">選擇地區</Text>
          </Box>
          {regions.map((r, i) => (
            <Button
              key={r}
              width="7.5em"
              mx="0.5em"
              active={i === activeRegion}
              onClick={() => setActiveRegion(i)}
            >
              {r}{r === '北部' && <Text.inline fontSize="80%">非雙北</Text.inline>}
            </Button>
          ))}
        </Flex>
        <DotsBg
          position="absolute"
          style={{
            width: 960 * resizeRatio,
            top: 270 * resizeRatio,
            left: 221 * resizeRatio,
            height: 430 * resizeRatio,
          }}
        >
          <Box position="absolute" top="0" left="1.5em" right="1.5em" bottom="1em">
            <Carousel
              config={{
                afterChange: i => {
                  setActiveIndex(i)
                  setActivated(draft => {
                    draft[i] = true
                  })
                },
              }}
              prevLabel={data[prevIndex].name}
              nextLabel={data[nextIndex].name}
            >
              {data.map((d, index) => (
                <Box key={`chart-${d.name}`} pt="2em">
                  <Flex ml="1.5em" alignItems="center">
                    <RoundTitleLabel mr="2em">{d.name}</RoundTitleLabel>
                    {/* <Text color="white" fontSize="1.375em">2013-2019年 {d.title}</Text> */}
                  </Flex>
                  {d.data && (
                    <StackedBars
                      isActive={activeIndex === index}
                      activated={activated && isActivated[index]}
                      width={912 * resizeRatio}
                      height={330 * resizeRatio}
                      data={d.data[regions[activeRegion]]}
                      xAccessor="年"
                      keys={Object.keys(schemas.showWithOther)}
                      xTick={{ label: '（年）' }}
                      yTick={{
                        label: d.axisLabel,
                        numTicks: 4,
                        tickFormat: get(d.tickFormat, [regions[activeRegion]]),
                      }}
                      colors={Object.values(schemas.showWithOther)}
                      valueFormat={(isFunction(d.valueFormat) ? d.valueFormat : get(d.valueFormat, regions[activeRegion])) || format('.0f')}
                      margin={{
                        top: 2.5,
                        left: get(d.marginLeft, regions[activeRegion], 5),
                      }}
                      showSum
                      showPercentOnTooltip
                      sumOverride={get(d.sumOverride, regions[activeRegion])}
                      em={em}
                      isMobile={isMobile}
                    />
                  )}
                </Box>
              ))}
            </Carousel>
          </Box>
        </DotsBg>
      </Container>
    </DotsBg>
  );
};

export default Page;
