import React from 'react';

import Box from '../../../components/Box'
import Text from '../../../components/Text'
import Image from '../../../components/Image'
import Container from '../../../components/Container'
import FullRight from '../../FullRight'

import right from './right.svg'
import rightBottom from './right-bottom.svg'
import key from './key.svg'

const Page = ({ resizeRatio, containerWidth }) => {
  return (
    <Box height="100%" position="relative" bg="orange">
      <Box position="absolute" whiteSpace="pre-wrap" style={{ top: 95 * resizeRatio, left: 120 * resizeRatio }}>
        <Text.h2 fontSize="3.75em" color="yellow">{`看表演之前的\n重要因素`}</Text.h2>
        <Text.h4 fontSize="3.125em" color="white">{`觀眾購票行為\n大解密`}</Text.h4>
      </Box>
      <FullRight src={right} containerWidth={containerWidth} />
      <Box position="absolute" style={{ width: 390 * resizeRatio, top: 497 * resizeRatio, right: 479 * resizeRatio }}>
        <Text.h4 fontSize="1.5em" color="white" mt={['-1em', 0]}>
          從想看一場表演到真正進場欣賞，在過程中觀眾做了哪些思考與選擇，讓我們一起觀察數據留下的線索。
        </Text.h4>
      </Box>
      <Box position="absolute" style={{ width: 528 * resizeRatio, left: 120 * resizeRatio, top: 305 * resizeRatio }}>
        <Image data-src={key} />
      </Box>
      <Container position="relative" mx="0" ml="auto" height="100%" zIndex={2} pointerEvents="none" width={containerWidth}>
        <Box position="absolute" right="0" bottom="0" left="0">
          <Image data-src={rightBottom} />
        </Box>
      </Container>
    </Box>
  );
};

export default Page;
