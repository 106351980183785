import React from 'react';

import Box from '../../../components/Box'
import Container from '../../../components/Container'
import Image from '../../../components/Image'

import bottom from './bottom.svg'

const Transition = ({ resizeRatio, containerWidth }) => {
  return (
    <Box
      style={{
        marginBottom: -56 * resizeRatio,
      }}
      bg="orange"
    >
      <Box
        bg="#7C3B25"
        pt="3.375em"
        position="relative"
        zIndex={1}
      >
        <Container
          mx="0"
          mr="auto"
          width={containerWidth}
        >
          <Image src={bottom} />
        </Container>
        <Box
          style={{
            left: 120 * resizeRatio,
            right: 0,
          }}
          position="absolute"
          borderBottom="0.125em solid black"
        />
      </Box>
    </Box>
  );
};

export default Transition;
