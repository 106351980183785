import { createElement } from 'react'

import browser from '../utils/browser'
import isIE from '../utils/isIE'
import TickLabel from './TickLabel'

export const tickLeft = (margin, scale, isMobile) => ({
  top: margin.top,
  left: 0,
  scale,
  labelOffset: 0,
  labelProps: {
    fontSize: '1em',
    fill: '#fff',
    writingMode: 'tb',
    transform: 'rotate(0)',
    fontWeight: 'bold',
    letterSpacing: isIE ? 0 : (browser.getBrowserName() === 'Chrome' ? '-0.5em' : '-0.25em'),
    x: -margin.left * (isMobile ? 0.9 : 0.75),
    y: '-0.25em',
  },
  stroke: '#fff',
  strokeWidth: '0.25em',
  tickStroke: '#fff',
  tickStrokeWidth: 1.5,
  tickLabelProps: () => ({
    fill: '#fff',
    textAnchor: 'end',
    fontSize: '1em',
    dx: '-0.25em',
    dy: '0.25em'
  }),
  tickComponent: ({ formattedValue, ...tickProps }) => createElement(TickLabel, tickProps, formattedValue),
})

export const tickBottom = (margin, scale, xMax, yMax) => ({
  top: yMax + margin.top,
  strokeWidth: '0.25em',
  stroke: '#fff',
  labelProps: {
    fill: '#fff',
    fontSize: '1em',
    x: xMax,
    y: '1.375em',
    fontWeight: 'bold',
  },
  scale,
  hideTicks: true,
  tickLabelProps: () => ({
    fill: '#fff',
    fontSize: '1.125em',
    textAnchor: 'middle',
    // dy: -18,
  }),
  tickComponent: ({ formattedValue, ...tickProps }) => createElement(TickLabel, { ...tickProps, y: '1.375em' }, formattedValue),
})
