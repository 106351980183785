import React from 'react';

import Box from '../../../components/Box'
import Container from '../../../components/Container'
import Image from '../../../components/Image'

import left from './left.svg'
import right from './right.svg'

const Transition = ({ containerWidth }) => {
  return (
    <Box position="relative" bg="orange" borderTop="black solid 0.2em">
      <Container mx="0" ml="0" mr="0" width={containerWidth}>
        <Image src={left} />
      </Container>
      <Box position="absolute" width="100%" top="0">
        <Container mx="0" ml="auto" mr="0" width={containerWidth}>
          <Image src={right} />
        </Container>
      </Box>
    </Box>
  );
};

export default Transition;
