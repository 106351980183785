import { useEffect, useState } from 'react';
import Papa from 'papaparse'
import some from 'lodash/some'

export default (csv, load) => {
  const [data, setData] = useState()
  useEffect(() => {
    if (load) {
      Papa.parse(csv, {
        download: true,
        header: true,
        complete: (results) => {
          setData(results.data.filter(d => some(d, Boolean)));
        }
      });
    }
  }, [load])
  return { data }
}
