import React from 'react';

import bg from './bg.svg'

import Box from '../../../components/Box'
import Container from '../../../components/Container'
import NumberAndText from '../../../components/NumberAndText'
import Text from '../../../components/Text'
import Image from '../../../components/Image'
import DotsBg from '../../../components/DotsBg'

const Page = ({ resizeRatio, containerWidth }) => {
  return (
    <DotsBg height="100%">
      <Container height="100%" position="relative" width={containerWidth}>
        <Box position="absolute" bottom="0" top="0" style={{ left: -69 * resizeRatio, right: -69 * resizeRatio }}>
          <Image data-src={bg} />
          <Box textAlign="center" color="white" position="absolute" style={{ top: 154 * resizeRatio }} left="50%" transform="translateX(-50%)" whiteSpace="nowrap">
            <Text.h2 color="yellow" fontSize="3.75em">當表演走向各地的觀眾</Text.h2>
            <Text.h4 fontSize="2.75em">各地區的消費數據觀察</Text.h4>
          </Box>
          <Box
            color="white"
            position="absolute"
            left="50%"
            transform="translateX(-50%)"
            style={{
              width: 598 * resizeRatio,
              top: 360 * resizeRatio,
            }}
          >
            <NumberAndText fontSize="1.5em" textAlign="center">近年全台各地陸續開幕新場館，滾動了當地或其他地區的藝文表演欣賞人流，改變表演藝術各地區的市場規模與消費樣態。</NumberAndText>
            <Box mt={['0.75em', '1.25em']} mx={['-2.5em', '-1em']} fontSize="0.9em">
              <NumberAndText>註1:各地區之認定係依國家發展委員會標準參考。</NumberAndText>
              <NumberAndText>註2:各地區消費數據以各節目演出地點之場館地址判定(地址標示不清者，在此未呈現)。</NumberAndText>
            </Box>
          </Box>
        </Box>
      </Container>
    </DotsBg>
  );
};

export default Page;
