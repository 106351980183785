import React, { useState, useMemo } from 'react';
import { format } from 'd3-format'
import { useImmer } from 'use-immer'

import box from './p3-box.csv'
import count from './p3-count.csv'
import event from './p3-event.csv'

import Carousel from '../../../components/Carousel'
import Box from '../../../components/Box'
import Container from '../../../components/Container'
import Text from '../../../components/Text'
import NumberAndText from '../../../components/NumberAndText'

import DotsBg from '../../../components/DotsBg'
import RoundTitleLabel from '../../../components/RoundTitleLabel'
import StackedBars from '../../../components/Charts/StackedBars'
import { schemas } from '../../../components/ThemeProvider/theme'

import useCSV from '../../../utils/useCSV'
import getYtickLabel from '../../getYtickLabel'

import years from '../../years'

const handleData = d => d && years.map(year => d.reduce((yd, dd) => {
  yd[dd['類別']] = dd[year]
  yd.sum += +dd[year]
  return yd
}, { 年: year, sum: 0 }))

const lineWidth = `${1.5 / 16}em`

const Page = ({ resizeRatio, activated, containerWidth, em, isMobile, toActive }) => {
  const { data: boxData } = useCSV(box, toActive)
  const { data: countData } = useCSV(count, toActive)
  const { data: eventData } = useCSV(event, toActive)

  const data = useMemo(() => [
    {
      name: '節目票房',
      axisLabel: getYtickLabel('金額', '億'),
      data: boxData,
      notes: [
        '註1：2013-2014年戲劇節目因太陽劇團大幅提升票房，視為單一年度特殊現象，後續相關統計分析將予以排除。',
        '註2：其他節目類別為非戲劇、音樂、舞蹈或親子節目，包含講座、工作坊等活動。',
      ],
      valueFormat: format('.1f'),
      yMax: 15,
      sumOverride: [ null, null, 11.1, null, null, 12.7, 13.6],
    },
    {
      name: '演出場次',
      axisLabel: getYtickLabel('場次', '場'),
      data: eventData,
      notes: [
        '註1：同一檔節目，每個售票場次皆計為一場。',
        '註2：其他節目類別為非戲劇、音樂、舞蹈或親子節目，包含講座、工作坊等活動。',
      ],
      leftMargin: 6.5,
    },
    {
      name: '售票張數',
      axisLabel: getYtickLabel('張數', '萬張'),
      data: countData,
      notes: [
        '註：其他節目類別為非戲劇、音樂、舞蹈或親子節目，包含講座、工作坊等活動。',
      ],
      valueFormat: format('.1f'),
      leftMargin: 5.5,
      sumOverride: [ null, null, 171.7, null, 182.1, 182.2],
    },
  ].map(d => ({ ...d, data: handleData(d.data) }), [boxData, eventData, countData]))
  const [activeIndex, setActiveIndex] = useState(0)
  const [isActivated, setActivated] = useImmer(() => [true])
  const prevIndex = activeIndex === 0 ? data.length - 1 : activeIndex - 1
  const nextIndex = activeIndex === data.length - 1 ? 0 : activeIndex + 1
  return (
    <Box height="100%" position="relative" bg="orange">
      <Container pt="2em" position="relative" height="100%" width={containerWidth}>
        <Box textAlign="center">
          <Text.h2 fontWeight="bold" color="primary" fontSize="2.75em">
            靠近一點 觀察市場變化
          </Text.h2>
          <Text.h4 color="white" fontSize="1.5em">
            戲劇與音樂節目票房 呈現明顯上升趨勢
          </Text.h4>
        </Box>
        <DotsBg
          position="absolute"
          border={`${lineWidth} solid black`}
          style={{
            width: 1160 * resizeRatio,
            top: 168 * resizeRatio,
            left: 104 * resizeRatio,
            height: 512 * resizeRatio,
          }}
        >
          {[0, 1].map(i => (
            <Box
              key={i}
              position="absolute"
              top={`-${lineWidth}`}
              left={i ? -1.5 : 'auto'}
              right={i ? 'auto' : -1.5}
              width="5.25em"
              bottom={`-${lineWidth}`}
              border={`${lineWidth} solid black`}
              bg="#8e452d"
            />
          ))}
          <Box position="absolute" top="0" left="5.25em" right="5.25em" bottom="2em">
            <Carousel
              config={{
                afterChange: i => {
                  setActiveIndex(i)
                  setActivated(draft => {
                    draft[i] = true
                  })
                },
              }}
              prevLabel={data[prevIndex].name}
              nextLabel={data[nextIndex].name}
              arrowsOffset="5.5em"
            >
              {data.map((d, index) => (
                <Box key={`chart-${d.name}`} pt="2.5em" px="2em">
                  <RoundTitleLabel>2013-2019年 {d.name}</RoundTitleLabel>
                  {d.data && (
                    <StackedBars
                      activated={activated && isActivated[index]}
                      data={d.data}
                      xAccessor="年"
                      keys={Object.keys(schemas.showWithOther)}
                      yMax={d.yMax}
                      xTick={{ label: '（年）' }}
                      yTick={{
                        label: d.axisLabel,
                        numTicks: 4,
                        tickValues: d.tickValues,
                      }}
                      colors={Object.values(schemas.showWithOther)}
                      valueFormat={d.valueFormat || format('.0f')}
                      margin={{
                        top: 2.5,
                        left: d.leftMargin || 4.5,
                        right: 6.5,
                      }}
                      showSum
                      showPercentOnTooltip
                      width={954 * resizeRatio}
                      height={390 * resizeRatio}
                      sumOverride={d.sumOverride}
                      em={em}
                      isMobile={isMobile}
                    />
                  )}
                </Box>
              ))}
            </Carousel>
          </Box>
        </DotsBg>
        <Box position="absolute" style={{ top: 707 * resizeRatio, left: 244 * resizeRatio }} color="white">
          {data.map(({ notes }, i) => i === activeIndex && (
            notes.map((note, n) => (
              <NumberAndText key={n}>{note}</NumberAndText>
            ))
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default Page;
