import React from 'react'
import { FaTwitter } from 'react-icons/fa';

import Box from '../../../components/Box';
import Flex from '../../../components/Flex';
import FB from '../../../components/FB';
import Line from '../../../components/Line';
import Link from '../../../components/Link';
import Button from '../../../components/Button';

const encodedURL = encodeURIComponent(process.env.REACT_APP_URL)

const icons = [
  {
    Icon: FB,
    is: Link,
    href: `https://www.facebook.com/sharer/sharer.php?u=${encodedURL}`,
  },
  {
    Icon: Line,
    is: Link,
    href: 'https://line.naver.jp/R/msg/text/?'},
  {
    Icon: FaTwitter,
    is: Link,
    href: `https://twitter.com/intent/tweet?text=${encodedURL}`,
  }
]

const ShareButton = (props) => (
  <Flex alignItems="center" justifyContent="center" px="0.5em" {...props}>
    {icons.map(({ Icon, is, href }, index) => (
      <Box ml={index && '0.5em'} key={index}>
        <Button.share
          color="white"
          bg="black"
          px={index === icons.length - 1 && '0.375em'}
          pt={index === icons.length - 1 && '0.375em'}
          pb={index === icons.length - 1 && '0.375em'}
          is={is}
          href={href}
        >
          <Icon size={index === icons.length - 1 && '1.25em'} />
        </Button.share>
      </Box>
    ))}
  </Flex>
)

export default ShareButton
