import React from 'react';

import Box from '../../../components/Box'
import Container from '../../../components/Container'
import Image from '../../../components/Image'

import hole from './hole.svg'
import left from './left.svg'
import right from './right.svg'

const Transition = ({ resizeRatio, containerWidth }) => {
  return (
    <Box bg="orange">
      <Box position="absolute" width={1} zIndex={1}>
        <Container
          mx="0"
          ml="0"
          mr="auto"
          position="relative"
          width={containerWidth}
        >
          <Image src={left} />
        </Container>
      </Box>
      <Box position="absolute" width={1} zIndex={1}>
        <Container
          mx="0"
          ml="auto"
          mr="0"
          position="relative"
          width={containerWidth}
        >
          <Image src={right} />
        </Container>
      </Box>
      <Box
        position="absolute"
        style={{
          left: 223 * resizeRatio,
          right: 223 * resizeRatio,
        }}
        borderBottom="0.125em solid black"
      />
      <Container
        position="relative"
        zIndex={1}
        name="2-3"
        width={containerWidth}
      >
        <Image src={hole} />
      </Container>
    </Box>
  );
};

export default Transition;
